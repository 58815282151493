import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import Banner from "../../../components/Banner";
import Footer from "../../../components/Footer";
import { Link } from "react-router-dom";
import "./style.css";
import { fetchClientLogoNames, fetchEvents, fetchHero, fetchRequestQuote } from "../../../network/services/fetchEvenement";
import { fetchPartnersClientsSectionData } from "../../../network/services/fetchHomeData";

function Evenement() {
  const [heroSection, setHeroSection] = useState(null);
  const [eventsSection, setEventsSection] = useState(null);
  const [requestQuoteSection, setQuoteSection] = useState(null);
  const [clientLogos, setClientLogos] = useState()
  const [logoNames, setLogoNames] = useState('');

  const lang = sessionStorage.getItem('langue') || 'fr';

  useEffect(() => {
    const fetchData = async () => {      

      const heroSectionData = await fetchHero();
      setHeroSection(heroSectionData);

      const eventsSectionData = await fetchEvents();
      setEventsSection(eventsSectionData);

      const requestQuoteData = await fetchRequestQuote();
      setQuoteSection(requestQuoteData);

      const names = await fetchClientLogoNames();
      setLogoNames(names);

      const clientLogosData = await fetchPartnersClientsSectionData();
      const limitedClientLogosData = clientLogosData.slice(0, 6); // Limite à 6 éléments
      setClientLogos(limitedClientLogosData);


    };

    fetchData();
  }, [])

  if (!eventsSection || !heroSection) return <div>Loading...</div>;

  return (
    <>
      <Header />
      <Sidebar />
      {heroSection && (
        <Banner title={heroSection[0]?.textePrincipal} backgroundImageUrl={heroSection[0]?.imageFond.asset.url} />
      )}

<section className="auto-container">
      <h1>{eventsSection.title}</h1>
      <div className="row">
        {eventsSection.events.map((event, index) => (
          <article className="card fl-left" key={index}>
            <section className="date">
              <time dateTime={new Date(event.date).toLocaleDateString('en-CA')}>
                <span>{new Date(event.date).getDate()}</span>
                <span>{new Date(event.date).toLocaleString('default', { month: 'short' })}</span>
              </time>
            </section>
            <section className="card-cont">
              <small>{event.artist}</small>
              <h3>{event.name}</h3>
              <div className="even-date">
                <i className="fa fa-calendar"></i>
                <time>
                  <span>{new Date(event.date).toLocaleDateString()}</span>
                  <span>{event.time}</span>
                </time>
              </div>
              <div className="even-info">
                <i className="fa fa-map-marker"></i>
                <p>{event.location}</p>
              </div>
              <a href="#" className={`bg bg-${event.status}`}>
                {event.status}
              </a>
            </section>
          </article>
        ))}
      </div>
    </section>

      <section
        class="clients-section-three style-two"
        style={{
          backgroundImage: "url(assets/images/background/pattern-21.png)",
        }}
      >
        <div class="auto-container">
          <div class="row clearfix">
          {requestQuoteSection && (
          <div className="title-column col-lg-5 col-md-12 col-sm-12">
            <div className="inner-column">
              <div className="title-box">
                <div className="title">
                  <span className="separater"></span>
                  {logoNames}
                </div>
                <h3>{requestQuoteSection.title}</h3>
                <div className="text">
                  {requestQuoteSection.description.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </div>
              </div>
              <a href="contact.html" className="read-more">
                Contact Now{" "}
                <span className="plus-box flaticon-plus-symbol"></span>
              </a>
            </div>
          </div>
        )}

            <div class="clients-column col-lg-7 col-md-12 col-sm-12">
              <div class="inner-column">
              <div className="row clearfix">
              {clientLogos && clientLogos.map((logo, index) => (
                <div class="client-box col-lg-4 col-md-4 col-sm-12">
                  <div class="image">
                    <a href="#">
                      <img src={logo.imageUrl} alt="" />
                    </a>
                  </div>
                </div>
              ))}
    </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Evenement;
