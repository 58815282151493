import client from "../sanity/sanityClient";

const lang = sessionStorage.getItem('langue') || 'fr'; // Récupère la langue stockée ou utilise le français par défaut

/**
 * Fetches the hero section data from Sanity for the ActiviteMission page.
 * @returns {Promise<Object|null>} The hero section data or null if an error occurs.
 */
export const fetchActiviteMissionHeroSectionData = async () => {
  try {
    const query = `
      *[_type == "ActiviteMission"][0]{
        "heroSection": heroSection[]{
          "textePrincipal": textePrincipal.${lang},
          "texteSecondaire": texteSecondaire.${lang},
          "description": description.${lang},
          "imageFond": imageFond.asset->{
            _id,
            url
          }
        }
      }
    `;

    const data = await client.fetch(query);
    console.log(data.heroSection);
    return data.heroSection || null;
  } catch (error) {
    console.error('Error fetching ActiviteMission hero section data:', error.message);
    return null;
  }
};

/**
 * Fetches the request quote section data from Sanity for the ActiviteMission page.
 * @returns {Promise<Object|null>} The request quote section data or null if an error occurs.
 */
export const fetchActiviteMissionRequestQuoteSectionData = async () => {
  try {
    const query = `
      *[_type == "ActiviteMission"][0]{
        "requestQuoteSection": requestQuoteSection{
          "title": title.${lang},
          "sousTitre": sousTitre.${lang},
          "description": description.${lang}
        }
      }
    `;

    const data = await client.fetch(query);
    return data.requestQuoteSection || null;
  } catch (error) {
    console.error('Error fetching ActiviteMission request quote section data:', error.message);
    return null;
  }
};

/**
 * Fetches the service block data from Sanity for the ActiviteMission page.
 * @returns {Promise<Object|null>} The service block data or null if an error occurs.
 */
export const fetchActiviteMissionServiceBlockData = async () => {
  try {
    const query = `
      *[_type == "ActiviteMission"][0]{
        "serviceBlock": serviceBlock[]{
          "title": title.${lang},
          "description": description.${lang},
          "image": image.asset->{
            _id,
            url
          }
        }
      }
    `;

    const data = await client.fetch(query);
    console.log(data.serviceBlock);
    return data.serviceBlock || null;
  } catch (error) {
    console.error('Error fetching ActiviteMission service block data:', error.message);
    return null;
  }
};
