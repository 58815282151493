// fetchHeaderData.js
import client from './../sanity/sanityClient';

const lang = sessionStorage.getItem('langue');

export const fetchHeaderData = async () => {
  try {
    const query = `*[_type == "header"][0]{
      logo,
      "descriptionLieu": descriptionLieu.${lang},
      navigationPrincipale[]{
        nom,
        url
      },
      informationsDeContact[]{
        appeler,
        horairesOuverture,
        email
      }
    }`;
    const headerData = await client.fetch(query);
    console.log(headerData)
    return headerData;
  } catch (error) {
    console.error(error.message)
  }
};
