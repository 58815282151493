import React from "react";
import { NavLink } from "react-router-dom";
import { getDatePost2 } from "../../helpers/utils";
import { urlFor } from "../../network/sanity/sanityClient";
import { productImage } from "../../assets";

const BlogV = ({ data,language }) => {
  console.log("date --- ", data?.date);
  return (
    <div className="w-100">
      <div className="d-flex flex-column w-100">
        {
          language==='fr'?(
            <NavLink to={`/new/${data?.category?.slug?.fr?.current}/${data?.slug.fr.current}`}>
          <img src={data?.image} className="blog-v-image" alt="" />
        </NavLink>
          ):(
            <NavLink to={`/new/${data?.category?.slug?.en?.current}/${data?.slug.en.current}`}>
          <img src={data?.image} className="blog-v-image" alt="" />
        </NavLink>
          )
        }
        
        <div className="pt-3">
          <NavLink
            to={`/new/${data?.category?.slug?.en?.current}/${data?.slug.en.current}`}
            className="title-blog"
          >
            {data?.title}
          </NavLink>
          <div className="pt-1">
            <NavLink
              to={`/category-new/${data?.category?.slug?.en?.current}`}
              className="title-category-blog"
            >
              {data?.category?.title}
            </NavLink>
          </div>
          <p className="card-text desc-categ-blog">{data?.smallDescription}</p>
          <div className="d-flex justify-content-end font-italic">
            <span className="text-muted">{data?.date && getDatePost2(data?.date)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogV;
