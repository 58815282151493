import React, { useEffect, useState } from 'react';
import { fetchRequestQuoteSectionData, fetchPartnersClientsSectionData } from '../network/services/fetchClientSection';

const ClientSection = () => {
  const lang = sessionStorage.getItem('langue');

  console.log('lang ------------------------  ================ ',lang)

  const [requestQuoteSection, setRequestQuoteSection] = useState(null);
  const [clientLogos, setClientLogos] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const quoteData = await fetchRequestQuoteSectionData(lang);
      const logosData = await fetchPartnersClientsSectionData(lang);

      setRequestQuoteSection(quoteData);
      setClientLogos(logosData);
    };

    fetchData();
  }, [lang]);

  return (
    <section
      className="clients-section-three style-two"
      style={{ backgroundImage: 'url(assets/images/background/pattern-21.png)' }}
    >
      <div className="auto-container">
        <div className="row clearfix">
          {requestQuoteSection && (
            <div className="title-column col-lg-5 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="title-box">
                  <div className="title">
                    <span className="separater"></span>
                    {requestQuoteSection?.sousTitre}
                  </div>
                  <h3>{requestQuoteSection?.title}</h3>
                  <div className="text">
                    {requestQuoteSection?.description.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </div>
                </div>
                <a href="contact.html" className="read-more">
                  {lang === 'fr' ? 'Contactez Maintenant' : lang === 'en' ? 'Contact Now' : 'Contato ahora'}{' '}
                  <span className="plus-box flaticon-plus-symbol"></span>
                </a>
              </div>
            </div>
          )}

          <div className="clients-column col-lg-7 col-md-12 col-sm-12">
            <div className="inner-column">
              <div className="row clearfix">
                {clientLogos && clientLogos?.map((logo, index) => (
                  <div className="client-box col-lg-4 col-md-4 col-sm-12" key={index}>
                    <div className="image">
                      <a href="#">
                        <img src={logo?.image} alt={logo?.name} />
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ClientSection;
