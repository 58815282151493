import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { fetchHeaderData } from "../network/services/fetchHeaderData";
import { urlFor } from "../network/services/urlImage";
import { fetchSettingsData } from "../network/services/fetchSettingsData";
import sanityClient from "../network/sanity/sanityClient";
import { getLangue, removeLangue, setLangue } from "../network/auth/Auth";
import { useNavigate } from "react-router-dom";
import BreadcrumbNews0 from "./BreadcrumbNews0";

function Header2News0({
  sendDataToParent,
  sendLanguage,
  showHeader,
  hideMenu,
  blog,
  menuName,
}) {
  const [headerData, setHeaderData] = useState(null);
  const [data, setData] = useState();
  const [settings, setSettings] = useState(null);
  const lang = sessionStorage.getItem("langue"); // Récupérer la langue stockée ou utiliser le français par défaut
  const [isHovered, setIsHovered] = useState(false);
  const [teamsCategory, setTeamsCategory] = useState([]);
  const [showMenu, setShowMenu] = useState(true);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState("");
  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };
  const handleMenu = () => {
    setShowMenu(!showMenu);
  };
  console.log("the name of menu ", menuName);
  useEffect(() => {
    if (showHeader) {
      console.log("Header is visible");
    }
  }, [showHeader]);

  const hideMenu2 = () => {
    hideMenu(); // Call the parent hideMenu function
  };

  const navigate = useNavigate();
  const sendData = (data) => {
    sendDataToParent(data);
  };

  const changeOtherPage = (data, item) => {
    sendDataToParent(data);
    navigate(
      `/other-page/${slugify(
        language === "fr"
          ? data?.subCategoryName?.fr
          : data?.subCategoryName?.en
      )}?key=${data?._key}`,
      {
        state: {
          ...data, // existing state
          nameMenu: item, // new state
        },
      }
    );
  };

  const changeTeamPage = (teamCat) => {
    navigate(
      `/teams/${
        language === "fr"
          ? teamCat?.slug?.fr?.current
          : teamCat?.slug?.en?.current
      }`,
      { state: teamCat }
    );
  };

  const [language, setLanguage] = useState(getLangue());

  useEffect(() => {
    const currentLanguage = getLangue();
    setLanguage(currentLanguage);
  }, []);

  const changeLangue = (newLang) => {
    setLangue(newLang);
    setLanguage(newLang);
  };

  const resetLangue = () => {
    removeLangue();
    setLanguage(getLangue());
  };

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "otherPages"][]`)
      .then((data) => {
        console.log(data);

        // Récupère le fragment d'URL
        const hash = window.location.hash.substring(1); // Supprime le premier caractère '#'
        const params = new URLSearchParams(hash.replace(/#/g, "&")); // Remplace '#' par '&' pour créer un format de query string

        // Extrais les valeurs des paramètres 'cat' et 'subcat'
        const catValue = params.get("cat");
        const subCatValue = params.get("subcat");

        // Convertir les valeurs en entiers
        const catIndex = parseInt(catValue, 10);
        const subCatIndex = parseInt(subCatValue, 10);

        console.log("catValue:", catIndex);
        console.log("subCatValue:", subCatIndex);

        // Vérifie si les valeurs sont des nombres valides
        if (!isNaN(catIndex) && !isNaN(subCatIndex)) {
          const selectedCategory = data[catIndex];
          const selectedSubCategory =
            selectedCategory?.subCategories[subCatIndex];

          console.log("selectedCategory:", selectedCategory);
          console.log("selectedSubCategory:", selectedSubCategory);

          if (selectedSubCategory) {
            sendData(selectedSubCategory);
          }
        }
        console.log("ici le resultat", data);
        setData(data);
      })
      .catch(console.error);

    sanityClient
      .fetch(
        `*[_type == "header"][0]{
        logo,
        "descriptionLieu": descriptionLieu,
        navigationPrincipale[]{
          nom,
          url
        },
        informationsDeContact[]{
          appeler,
          horairesOuverture,
          email
        }
      }`
      )
      .then((data) => {
        setHeaderData(data);
      })
      .catch(console.error);

    sanityClient
      .fetch(
        `*[_type == "settings"]{
          logoEntreprise{
            asset->{
              _id,
              url
            }
          },
          iconicWords,
          reseauxSociaux,
          nomEntreprise,
          adresse,
          telephone,
          email
        }[0]`
      )
      .then((data) => {
        console.log("header data", data);
        setSettings(data);
      })
      .catch(console.error);

    sanityClient
      .fetch(
        `*[_type == "teamCategory"]{
        _id,
          "slug":slug,
          "title":title,
          "image":image.asset->url,
          "description":description,
            }`
      )
      .then((data) => {
        console.log("header data2", data);
        setTeamsCategory(data);
      })
      .catch(console.error);
  }, []);
  const buttonStyles = {
    backgroundColor: isHovered ? "#FFBB1B" : "#1B5E20", // Change these to your colors
    transition: "background-color 0.3s",
  };

  const changeLanguage = (e) => {
    setLangue(e.target.value);
    setLanguage(e.target.value);
    sendLanguage(e.target.value);
  };

  if (!headerData) return <div></div>;

  // Fonction pour normaliser les noms de catégories en fonction de la langue
  const getCategoryName = (category) => {
    return language === "fr"
      ? category?.fr?.toLowerCase()
      : category?.en?.toLowerCase();
  };

  // Ordre spécifique des catégories à mettre à la fin pour chaque langue
  const orderEn = ["sea bed authority", "unpga", "news"];
  const orderFr = ["autorite des fonds marins", "pagnu", "actualités"];

  // Sélection de l'ordre basé sur la langue actuelle
  const order = language === "fr" ? orderFr : orderEn;

  // Fonction de tri personnalisée
  const sortByCategory = (a, b) => {
    const categoryA = getCategoryName(a.category);
    const categoryB = getCategoryName(b.category);

    const indexA = order.indexOf(categoryA);
    const indexB = order.indexOf(categoryB);

    // Si les deux catégories sont dans l'ordre spécifié, maintenir leur ordre
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }

    // Si une catégorie est spécifiée et l'autre non, mettre la spécifiée en dernier
    if (indexA !== -1) return 1;
    if (indexB !== -1) return -1;

    // Garder l'ordre relatif pour les autres catégories
    return 0;
  };

  // Trier les données
  const sortedData = data?.slice().sort(sortByCategory);

  // Fonction pour générer un slug à partir d'un texte
  const slugify = (input) => {
    // Vérifiez si l'entrée est une chaîne, sinon fournissez une chaîne vide
    if (typeof input !== "string") {
      console.error("Expected a string input, but got:", typeof input, input);
      return ""; // Retourne une chaîne vide ou une valeur par défaut
    }

    return input
      .toLowerCase() // Convertir en minuscules
      .trim() // Supprimer les espaces en début et fin de chaîne
      .replace(/\s+/g, "-") // Remplacer les espaces par des tirets
      .replace(/[^\w\-]+/g, "") // Supprimer les caractères non alphanumériques
      .replace(/\-\-+/g, "-"); // Remplacer les tirets multiples par un seul tiret
  };

  return (
    <>
      <header className="main-header header-style-one">
        <div className="header-top">
          <div className="auto-container">
            <div className="clearfix">
              <div className="top-left clearfix">
                <div className="text">
                  <span className="icon flaticon-chat-bubble"></span>{" "}
                  {language === "fr"
                    ? headerData?.descriptionLieu?.fr
                    : headerData?.descriptionLieu?.en}
                </div>
              </div>

              <div className="top-right pull-right">
                <ul className="nav-list">
                  <li>
                    <select
                      className="language-selector"
                      value={language}
                      onChange={(e) => changeLanguage(e)}
                    >
                      <option value="en">English</option>
                      <option value="fr">Français</option>
                    </select>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="header-upper">
          <div className="auto-container">
            <div className="clearfix">
              <div className="pull-left logo-box">
                <div className="logo">
                  <a href="/">
                    {headerData?.logo ? (
                      <img
                        src={urlFor(headerData.logo).url()}
                        alt=""
                        title=""
                      />
                    ) : (
                      ""
                    )}
                  </a>
                </div>
              </div>

              <div className="pull-right upper-right clearfix">
                <div className="upper-column info-box">
                  <div className="icon-box">
                    <a href="tel:+16468501826">
                      <span className="fa fa-phone"></span>
                    </a>
                  </div>

                  <ul>
                    <li>
                      <strong>
                        {language === "fr"
                          ? "Appelez-nous à tout moment"
                          : "Call Us Anytime"}
                      </strong>
                    </li>

                    {headerData?.informationsDeContact.map((info, index) => (
                      <li key={index}>
                        {language === "fr" ? info.appeler.fr : info.appeler.en}
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="upper-column info-box">
                  <div className="icon-box">
                    <span className="fa fa-clock-o"></span>
                  </div>

                  <ul>
                    <li>
                      <strong>
                        {language === "fr"
                          ? "Heures d'ouverture"
                          : "Opening Time"}
                      </strong>
                    </li>

                    {headerData?.informationsDeContact &&
                      headerData?.informationsDeContact?.map((info, index) => (
                        <li key={index}>
                          {language === "fr"
                            ? info.horairesOuverture.fr
                            : info.horairesOuverture.en}
                        </li>
                      ))}
                  </ul>
                </div>

                <div className="upper-column info-box">
                  <div className="icon-box">
                    <Link to="/contact">
                      <a style={{ position: "inline-flex", top: "13px" }}>
                        <span className="fa fa-envelope-o"></span>
                      </a>
                    </Link>
                  </div>

                  <ul>
                    <li>
                      <strong>
                        {language === "fr"
                          ? "Envoyez-nous un e-mail"
                          : "Email Us Now"}
                      </strong>
                    </li>

                    {headerData?.informationsDeContact &&
                      headerData?.informationsDeContact?.map((info, index) => (
                        <li key={index}>{info.email}</li>
                      ))}
                  </ul>
                </div>
                <div className="upper-column info-box">
                  <div className="icon-box">
                    {" "}
                    <a target="_blank" href="https://webmail.migadu.com">
                      <span className="fa fa-envelope-o"></span>
                    </a>
                  </div>

                  <ul>
                    <li>
                      <strong>
                        <a
                          target="_blank"
                          href="https://webmail.migadu.com"
                          style={{ position: "relative", top: "13px" }}
                        >
                          {language === "fr" ? "Webmail" : "Webmail"}
                        </a>
                      </strong>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-lower">
          <div className="auto-container">
            <div className="nav-outer clearfix">
              <div className="mobile-nav-toggler" onClick={toggleMobileNav}>
                {/* <span className="icon flaticon-menu-2"></span> */}
              </div>

              <>
                <nav className="main-menu show navbar-expand-md">
                  <div className="navbar-header">
                    <button
                      className="navbar-toggler"
                      type="button"
                      dataToggle="collapse"
                      dataTarget="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span className="icon-bar"></span>

                      <span className="icon-bar"></span>

                      <span className="icon-bar"></span>
                    </button>
                  </div>

                  <div
                    className="clearfix"
                    style={{
                      position: "relative",
                      float: "left",
                      width: "100%",
                    }}
                  >
                    <BreadcrumbNews0 language={language} />
                  </div>
                </nav>

                <div className="options-box clearfix"></div>
              </>

              <></>
            </div>
          </div>
        </div>

        <div className="header-lower">
          <div className="auto-container">
            <div className="nav-outer clearfix">
              <div className="mobile-nav-toggler" onClick={toggleMobileNav}>
                {/* <span className="icon flaticon-menu-2"></span> */}
              </div>

              <>
                <nav className="main-menu show navbar-expand-md">
                  <div className="navbar-header">
                    <button
                      className="navbar-toggler"
                      type="button"
                      dataToggle="collapse"
                      dataTarget="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span className="icon-bar"></span>

                      <span className="icon-bar"></span>

                      <span className="icon-bar"></span>
                    </button>
                  </div>

                  <div
                    className="navbar-collapse collapse clearfix"
                    id="navbarSupportedContent"
                  >
                    <ul className="navigation clearfix">
                      {language === "fr" ? (
                        <li>
                          <Link to="/" style={{}}>
                            Accueil
                          </Link>
                        </li>
                      ) : (
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                      )}
                      {sortedData &&
                        sortedData.map((item, index) => (
                          <li
                            className="dropdown position-static"
                            key={index}
                            onClick={handleMenu}
                          >
                            <a href="#">
                              {language === "fr"
                                ? item?.category?.fr
                                : item?.category?.en}
                            </a>
                            {showMenu ? (
                              <ul>
                                <div className="grid-menu">
                                  {item?.subCategories &&
                                    item?.subCategories.map(
                                      (subCat, subIndex) => (
                                        <div
                                          className="grid-item-menu"
                                          key={subIndex}
                                        >
                                          <a
                                            className="submenu"
                                            onClick={() =>
                                              changeOtherPage(subCat, item)
                                            }
                                          >
                                            {language === "fr"
                                              ? subCat?.subCategoryName?.fr
                                              : subCat?.subCategoryName?.en}
                                          </a>
                                          <hr className="hr-submenu" />
                                          <div className="submenu-content">
                                            {language === "fr"
                                              ? subCat?.title?.fr
                                              : subCat?.title?.en}
                                          </div>
                                        </div>
                                      )
                                    )}
                                </div>
                              </ul>
                            ) : (
                              ""
                            )}
                          </li>
                        ))}
                      <li>
                        <Link to="/news2">
                          {language === "fr" ? "Actualités" : "News"}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>

                <div className="options-box clearfix">
                  <div className="button-box">
                    <Link
                      to="/contact"
                      className="theme-btn btn-style-one"
                      style={buttonStyles}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                    >
                      <span className="txt">
                        Contact <i className="fa fa-phone"></i>
                      </span>
                    </Link>
                  </div>
                </div>
              </>

              <></>
            </div>
          </div>
        </div>

        <div className="mobile-menu">
          <div className="menu-backdrop"></div>

          <div className="close-btn">
            <span className="icon flaticon-multiply"></span>
          </div>

          <nav className="menu-box">
            <div className="nav-logo">
              <a href="index-2.html">
                <img src="assets/images/logo.png" alt="" title="" />
              </a>
            </div>

            <div className="menu-outer"></div>
          </nav>
        </div>
      </header>
    </>
  );
}

export default Header2News0;
