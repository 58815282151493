// fetchFooterData.js
import client from "./../sanity/sanityClient";

export const fetchFooterData = async (lang) => {
  try {
    const query = `*[_type == "footer"][0]{
      descriptionEntreprise,
      liensUtiles[]{
        nom,
        url
      },
       liensUtiles2[]{
        nom,
        url
      },
      formulaireEstimationGratuite{
        titre,
        description,
        formulaire
      },
      coordonneesContact{
        adresse,
        telephone,
        email
      },
      newsletter{
        titleNewsletter,
        descriptionNewsletter,
        formNewsletter,
        titre,
        description,
        formulaire
      },
      reseauxSociaux[]{
        nom,
        url
      }
    }`;
    const footerData = await client.fetch(query);
    return footerData;
  } catch (error) {
    console.error(error.message);
    return null;
  }
};

