import client from '../sanity/sanityClient';

const lang = sessionStorage.getItem('langue') || 'fr';

export const fetchBannerSection = async () => {
  const query = `*[_type == "PaysMembre"][0]{
    bannerSection[]{
      "textePrincipal": textePrincipal.${lang},
      "texteSecondaire": texteSecondaire.${lang},
      "description": description.${lang},
      imageFond{
        asset->{
          _id,
          url
        }
      }
    }
  }`;
  const data = await client.fetch(query);
  console.log(data.bannerSection);
  return data.bannerSection;
}

export const fetchHero = async () => {
  const query = `*[_type == "PaysMembre"]{
    heroSection{
      "title": title.${lang},
      date,
      author,
      image{
        asset->{
          _id,
          url
        }
      }
    }
  }[0]`;

  try {
    const data = await client.fetch(query);
    console.log(data.heroSection);
    return data.heroSection;
  } catch (error) {
    console.error('Error fetching hero section:', error);
    return null;
  }
};

export const fetchBlog = async () => {
  const query = `*[_type == "PaysMembre"]{
    blogSection{
      "title": title.${lang},
      "content": content[].${lang},
      galleryImages[]{
        asset->{
          _id,
          url
        }
      },
      tags[]{
        ${lang}
      },
      socialSharing[]{
        platform,
        link,
        shareCount
      }
    }
  }[0]`;

  try {
    const data = await client.fetch(query);
    console.log(data.blogSection);
    return data.blogSection;
  } catch (error) {
    console.error('Error fetching blog section:', error);
    return null;
  }
};

export const fetchSidebar = async () => {
  const query = `*[_type == "PaysMembre"]{
    sidebarSection{
      relevantDocuments[]{
        "title": title.${lang},
        link,
        count
      },
      otherCommittees[]{
        "title": title.${lang},
        link,
        date,
        image{
          asset->{
            _id,
            url
          }
        }
      },
      gallery[]{
        asset->{
          _id,
          url
        }
      }
    }
  }[0]`;

  try {
    const data = await client.fetch(query);
    console.log(data.sidebarSection);
    return data.sidebarSection;
  } catch (error) {
    console.error('Error fetching sidebar section:', error);
    return null;
  }
};

export const fetchClients = async () => {
  const query = `*[_type == "PaysMembre"]{
    clientsSection{
      "title": title.${lang},
      "description": description.${lang},
      clients[]{
        name,
        image{
          asset->{
            _id,
            url
          }
        }
      }
    }
  }[0]`;

  try {
    const data = await client.fetch(query);
    console.log(data.clientsSection);
    return data.clientsSection;
  } catch (error) {
    console.error('Error fetching clients section:', error);
    return null;
  }
};
