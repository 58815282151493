import client from "../sanity/sanityClient";

const lang = sessionStorage.getItem('langue') || 'fr';

export async function getHeroSection() {
  const query = `*[_type == "DecouvrirCameroun"][0]{
    "heroSection": heroSection[]{
      "textePrincipal": textePrincipal.${lang},
      "texteSecondaire": texteSecondaire.${lang},
      "description": description.${lang},
      "boutonsAppelAction": boutonsAppelAction[]{
        "texte": texte.${lang},
        "url": url
      },
      "imageFond": imageFond.asset->{
        _id,
        url
      }
    }
  }`;

  try {
    const data = await client.fetch(query);
    return data.heroSection || [];
  } catch (error) {
    console.error('Erreur lors de la récupération de la section Hero:', error);
    return [];
  }
}

export async function getAboutSection() {
  const query = `*[_type == "DecouvrirCameroun"][0]{
  "aboutSection": aboutSection{
    "title": title.${lang},
    "sousTitle": sousTitle.${lang},
    "backgroundPattern": backgroundPattern.asset->url,
    "image": image.asset->url,
    "experience": experience,
    "description": description.${lang},
    "readMoreLink": readMoreLink,
    "contactNumber": contactNumber,
    "signature": signature.asset->url
  }
}`;

  try {
    const data = await client.fetch(query);
    return data.aboutSection || {};
  } catch (error) {
    console.error('Erreur lors de la récupération de la section About:', error);
    return {};
  }
}

export async function getCounterSection() {
  const query = `*[_type == "DecouvrirCameroun"][0]{
    "counterSection": counterSection{
      "professionalWorkers": professionalWorkers,
      "projectsCompleted": projectsCompleted,
      "customersBenefit": customersBenefit,
      "awardsCertifications": awardsCertifications
    }
  }`;

  try {
    const data = await client.fetch(query);
    return data.counterSection || {};
  } catch (error) {
    console.error('Erreur lors de la récupération de la section Counter:', error);
    return {};
  }
}

export async function getVideoProjectSection() {
  const query = `*[_type == "DecouvrirCameroun"][0]{
    "videoProjectSection": videoProjectSection{
      "title": title.${lang},
      "subtitle": subtitle.${lang},
      "description": description.${lang},
      "videoUrl": videoUrl,
      "videoImage": videoImage.asset->url,
      "backgroundImage": backgroundImage.asset->url,
      "patternImage": patternImage.asset->url
    }
  }`;

  try {
    const data = await client.fetch(query);
    return data.videoProjectSection || {};
  } catch (error) {
    console.error('Erreur lors de la récupération de la section Video Project:', error);
    return {};
  }
}

export async function getSkillsSection() {
  const query = `*[_type == "DecouvrirCameroun"][0]{
    "skillsSection": skillsSection{
      "title": title.${lang},
      "subtitle": subtitle.${lang},
      "description": description.${lang},
      "skills": skills[]{
        "title": title.${lang},
        "percentage": percentage
      },
      "teamMembers": teamMembers{
        "name": name.${lang},
        "role": role.${lang},
        "image": image.asset->url,
        "socialLinks": socialLinks[]{
          "icon": icon,
          "link": link,
          "platform": platform.${lang}
        }
      }
    }
  }`;

  try {
    const data = await client.fetch(query);
    return data.skillsSection || {};
  } catch (error) {
    console.error('Erreur lors de la récupération de la section Skills:', error);
    return {};
  }
}

export async function getChoiceSection() {
  const query = `*[_type == "DecouvrirCameroun"][0]{
    "choiceSection": choiceSection{
      "title": title.${lang},
      "subtitle": subtitle.${lang},
      "description": description.${lang},
      "image": image.asset->url,
      "imageTwo": imageTwo.asset->url,
      "features": features[]{
        ${lang}
      }
    }
  }`;

  try {
    const data = await client.fetch(query);
    return data.choiceSection || {};
  } catch (error) {
    console.error('Erreur lors de la récupération de la section Choice:', error);
    return {};
  }
}

export async function getRequestQuoteSection() {
  const query = `*[_type == "DecouvrirCameroun"][0]{
    "requestQuoteSection": requestQuoteSection[]{
      "title": title.${lang},
      "description": description.${lang}
    }
  }`;

  try {
    const data = await client.fetch(query);
    return data.requestQuoteSection || {};
  } catch (error) {
    console.error('Erreur lors de la récupération de la section Request Quote:', error);
    return {};
  }
}

export async function getClientLogoSection() {
  const query = `*[_type == "DecouvrirCameroun"][0]{
    "clientLogo": clientLogo[]{
      "name": name.${lang},
      "image": image.asset->url
    }
  }`;

  try {
    const data = await client.fetch(query);
    return data.clientLogo || [];
  } catch (error) {
    console.error('Erreur lors de la récupération de la section Client Logo:', error);
    return [];
  }
}


export const fetchListSectionData = async () => {
  const query = `
  *[_type == "DecouvrirCameroun"][0]{
    "listSection": listSection{
      sectionList[]{
      "title": title.${lang},
      "image": image.asset->url,
      "description": description.${lang},
      "position": position
    }
    }
  }
  `;
  try {
    const data = await client.fetch(query);
    console.log(data.listSection.sectionList)
    return data.listSection.sectionList || [];
  } catch (error) {
    console.error('Error fetching list section data:', error);
    return [];
  }
}
