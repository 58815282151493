import client from "../sanity/sanityClient";

const lang = sessionStorage.getItem('langue') || 'fr'; // Récupère la langue stockée ou utilise le français par défaut

/**
 * Fetches the hero section data from Sanity for the ActiviteMission page.
 * @returns {Promise<Object|null>} The hero section data or null if an error occurs.
 */
export const fetchAutoriteMaritineHeroSectionData = async () => {
  try {
    const query = `
      *[_type == "AutoriteMaritine"][0]{
        "heroSection": heroSection[]{
          "textePrincipal": textePrincipal.${lang},
          "texteSecondaire": texteSecondaire.${lang},
          "description": description.${lang},
          "imageFond": imageFond.asset->{
            _id,
            url
          }
        }
      }
    `;

    const data = await client.fetch(query);
    return data.heroSection || null;
  } catch (error) {
    console.error('Error fetching ActiviteMission hero section data:', error.message);
    return null;
  }
};

/**
 * Fetches the request quote section data from Sanity for the ActiviteMission page.
 * @returns {Promise<Object|null>} The request quote section data or null if an error occurs.
 */
export const fetchAutoriteMaritineRequestQuoteSectionData = async () => {
  try {
    const query = `
      *[_type == "AutoriteMaritine"][0]{
        "requestQuoteSection": requestQuoteSection{
          "title": title.${lang},
          "sousTitre": sousTitre.${lang},
          "description": description.${lang}
        }
      }
    `;

    const data = await client.fetch(query);
    return data.requestQuoteSection || null;
  } catch (error) {
    console.error('Error fetching ActiviteMission request quote section data:', error.message);
    return null;
  }
};

/**
 * Fetches the service block data from Sanity for the ActiviteMission page.
 * @returns {Promise<Object|null>} The service block data or null if an error occurs.
 */
export const fetchAutoriteMaritineGalleryBlockData = async () => {
  try {
    const query = `
    *[_type == "AutoriteMaritine"][0] {
      "gallerySection": {
        "title": gallerySection.title,
        "subtitle": gallerySection.subtitle,
        "backgroundImage": gallerySection.backgroundImage.asset->url
      },
      "projects": gallerySection.projects[]{
        "image": image.asset->url,
        "category": category,
        "title": title,
        "link": link
      }
    }
`;

    const data = await client.fetch(query);
    console.log(data)
    return data || null;
  } catch (error) {
    console.error('Error fetching ActiviteMission service block data:', error.message);
    return null;
  }
};
