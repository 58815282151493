import client from "../sanity/sanityClient";

const lang = sessionStorage.getItem('langue') || 'fr'; // Récupère la langue stockée ou utilise le français par défaut

/**
 * Fetches the banner section data from Sanity for the DossiersSpeciaux page.
 * @returns {Promise<Object|null>} The banner section data or null if an error occurs.
 */
export const fetchDossiersSpeciauxBannerSectionData = async () => {
  try {
    const query = `
      *[_type == "DossiersSpeciaux"][0]{
        "bannerSection": bannerSection[]{
          "textePrincipal": textePrincipal.${lang},
          "texteSecondaire": texteSecondaire.${lang},
          "description": description.${lang},
          "imageFond": imageFond.asset->{
            _id,
            url
          }
        }
      }
    `;

    const data = await client.fetch(query);
    console.log(data.bannerSection);
    return data.bannerSection || null;
  } catch (error) {
    console.error('Error fetching DossiersSpeciaux banner section data:', error.message);
    return null;
  }
};


/**
 * Fetches the blog section data from Sanity for the DossiersSpeciaux page.
 * @returns {Promise<Object|null>} The blog section data or null if an error occurs.
 */
export const fetchDossiersSpeciauxBlogSectionData = async () => {
  try {
    const query = `
      *[_type == "DossiersSpeciaux"][0]{
        "blogSection": blogSection{
          "title": title.${lang},
          "content": content[]{
            ${lang}
          },
          "galleryImages": galleryImages[]{
            asset->{
              _id,
              url
            }
          },
          "tags": tags[]{
            ${lang}
          },
          "socialSharing": socialSharing[]{
            platform,
            link,
            shareCount
          }
        }
      }
    `;

    const data = await client.fetch(query);
    console.log(data.blogSection);
    return data.blogSection || null;
  } catch (error) {
    console.error('Error fetching DossiersSpeciaux blog section data:', error.message);
    return null;
  }
};

/**
 * Fetches the sidebar section data from Sanity for the DossiersSpeciaux page.
 * @returns {Promise<Object|null>} The sidebar section data or null if an error occurs.
 */
export const fetchDossiersSpeciauxSidebarSectionData = async () => {
  try {
    const query = `
      *[_type == "DossiersSpeciaux"][0]{
        "sidebarSection": sidebarSection{
          "relevantDocuments": relevantDocuments[]{
            "title": title.${lang},
            "link": link,
            "count": count
          },
          "otherCommittees": otherCommittees[]{
            "title": title.${lang},
            "link": link,
            "date": date,
            "image": image.asset->{
              _id,
              url
            }
          },
          "gallery": gallery[]{
            asset->{
              _id,
              url
            }
          }
        }
      }
    `;

    const data = await client.fetch(query);
    console.log(data.sidebarSection);
    return data.sidebarSection || null;
  } catch (error) {
    console.error('Error fetching DossiersSpeciaux sidebar section data:', error.message);
    return null;
  }
};

/**
 * Fetches the clients section data from Sanity for the DossiersSpeciaux page.
 * @returns {Promise<Object|null>} The clients section data or null if an error occurs.
 */
export const fetchDossiersSpeciauxClientsSectionData = async () => {
  try {
    const query = `
      *[_type == "DossiersSpeciaux"][0]{
        "clientsSection": clientsSection{
          "title": title.${lang},
          "description": description.${lang},
          "clients": clients[]{
            name,
            "image": image.asset->{
              _id,
              url
            }
          }
        }
      }
    `;

    const data = await client.fetch(query);
    console.log(data.clientsSection);
    return data.clientsSection || null;
  } catch (error) {
    console.error('Error fetching DossiersSpeciaux clients section data:', error.message);
    return null;
  }
};
