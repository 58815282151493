import React from "react";

const SkeletonTitleCategory = () => {
    return (
        <div className="w-100">
            <div className="d-flex flex-column w-100">
                <div className="pt-3">
                    <div className="skeleton skeleton-title"></div>                                    
                </div>
            </div>
        </div>
    );
};

export default SkeletonTitleCategory;
