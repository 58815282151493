// sanityClient.js

import { createClient } from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

export default createClient({
  projectId: "5pzop2m2", // Remplacez par l'ID de votre projet
  dataset: "production", // Remplacez par votre dataset
  useCdn: true,
  //token: "skOLfgpbskFozT2TJZRXxEdXvuZ8hYdDvAA07GO71kAdRka9k00AYz5rxXWD3p26sS2RtHoT0PzyD4DNRgXoDEvyQQPS0F0uf1DUbQ5mkgVDRADVTi4jHkmUiYVlRu4LRVbBWotKzfQ1YzQOtxwS7PjF6tD6NxJcOjO4ip4n0Z7VJPK8VcTm"
});

const builder = imageUrlBuilder(createClient);

export function urlFor(source) {
  return builder.image(source);
}
