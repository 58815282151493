import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import Banner from "../../../components/Banner";
import Footer from "../../../components/Footer";
import { fetchHero, fetchListSectionData, fetchServices } from "../../../network/services/fetchTourisme";
import Loading from "../../../components/Loading";

function TourismeCameroun() {
  const [heroSection, setHeroSection] = useState(null);
  const [servicesPage, setServicesPage] = useState(null);

  const lang = sessionStorage.getItem('langue')

  const [sections, setSections] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const sectionData = await fetchListSectionData();
      setSections(sectionData);
    };

    fetchData();
  }, [lang]);

  useEffect(() => {
    const getData = async () => {
      const HeroData = await fetchHero();
      setHeroSection(HeroData);

      const ServicesData = await fetchServices();
      setServicesPage(ServicesData);
    };

    getData();
  }, []);

  if (!servicesPage || !heroSection) return <Loading />;

  return (
    <>
      {heroSection ? (<>
        <Header />
        <Sidebar />
        {heroSection && (
          <Banner title={heroSection[0]?.textePrincipal} backgroundImageUrl={heroSection[0]?.imageFond?.asset.url} />
        )}

        {/* <section className="services-page-section">
      <div className="auto-container">
        <div className="row clearfix">
          {servicesPage.serviceBlocks.map((service, index) => (
            <div key={index} className={`service-block-four ${service.style} col-lg-4 col-md-6 col-sm-12`}>
              <div className="inner-box">
                <div className="image">
                  <a href={service.link}>
                    <img src={service.image.asset.url} alt="" />
                  </a>
                </div>
                <div className="lower-content">
                  <div className={`icon ${service.icon}`}></div>
                  <h4>
                    <a href={service.link}>
                      {service.title}
                    </a>
                  </h4>
                  <div className="text">
                    {service.text}
                  </div>
                  <a href={service.link} className="read-more">
                  {lang === 'fr'
                      ? 'Reagrdez Plus'
                      : lang === 'en'
                      ? 'Read More'
                      : 'mas'}{" "} <span className="plus flaticon-plus-symbol"></span>
                  </a>
                </div>
              </div>
            </div>
          ))}
          <div className="content-side col-lg-12 col-md-12 col-sm-12">
            <div className="our-shops">
              <div className="shop-section">
                <div className="our-shops">
                  <ul className="styled-pagination text-center">
                    <li className="prev">
                      <a href={servicesPage.pagination.prevLink}>
                        <span className="fa fa-angle-left"></span>
                      </a>
                    </li>
                    <li className="next">
                      <a href={servicesPage.pagination.nextLink}>
                        <span className="fa fa-angle-right"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> */}

        <div>
          {sections && sections?.map((section, index) => (
            <section key={index} className="choice-section style-two">
              <div className="auto-container">
                <div className="row clearfix">
                  {section.position === 'left' ? (
                    <>
                      {/* Image Column */}
                      <div className="image-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner-column">
                          <div
                            className="pattern-layer"
                            style={{}}
                          />
                          <div className="image">
                            <img src={section.image} alt="" style={{ height: "100px !important;", width: "100%" }} />
                          </div>
                        </div>
                      </div>
                      {/* Content Column */}
                      <div className="content-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner-column">
                          <div className="sec-title style-two">
                            <div className="separater" />
                            <h2>{section.title}</h2>
                          </div>
                          <div className="text">
                            <p>{section.description}</p>
                          </div>
                          <a href="#" className="read-more">
                            Read More <span className="plus flaticon-plus-symbol" />
                          </a>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {/* Content Column */}
                      <div className="content-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner-column">
                          <div className="sec-title style-two">
                            <div className="separater" />
                            <h2>{section.title}</h2>
                          </div>
                          <div className="text">
                            <p>{section.description}</p>
                          </div>
                          <a href="#" className="read-more">
                            Read More <span className="plus flaticon-plus-symbol" />
                          </a>
                        </div>
                      </div>
                      {/* Image Column */}
                      <div className="image-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner-column">
                          <div
                            className="pattern-layer"
                            style={{ backgroundImage: `url(${section.image})` }}
                          />
                          <div className="image">
                            <img src={section.image} alt="" style={{ height: "100px !important;", width: "70%" }} />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </section>
          ))}
        </div>

        <Footer />
      </>) : <Loading />}
    </>
  );
}

export default TourismeCameroun;
