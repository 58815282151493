import client from './../sanity/sanityClient';

const lang = sessionStorage.getItem('langue') || 'fr';

export const fetchHero = async () => {
  const query = `*[_type == "AdminOrganization"]{
    heroSection[]{
      "textePrincipal": textePrincipal.${lang},
      "texteSecondaire": texteSecondaire.${lang},
      "description": description.${lang},
      imageFond{
        asset->{
          _id,
          url
        }
      }
    }
  }[0]`;

  try {
    const data = await client.fetch(query);
    console.log(data.heroSection)
    return data.heroSection;
  } catch (error) {
    console.error('Error fetching hero section:', error);
    return null;
  }
};

export const fetchServices = async () => {
  const query = `*[_type == "AdminOrganization"]{
    servicesPageSection{
      serviceBlocks[]{
        style,
        image{
          asset->{
            _id,
            url
          }
        },
        icon,
        "title": title.${lang},
        "text": text.${lang},
        link
      },
      pagination{
        prevLink,
        nextLink
      }
    }
  }[0]`;

  try {
    const data = await client.fetch(query);
    console.log(data.servicesPageSection)
    return data.servicesPageSection;
  } catch (error) {
    console.error('Error fetching services page:', error);
    return null;
  }
};

export const fetchListSectionData = async () => {
  const query = `
  *[_type == "AdminOrganization"][0]{
    "listSection": listSection{
      sectionList[]{
      "title": title.${lang},
      "image": image.asset->url,
      "description": description.${lang},
      "position": position
    }
    }
  }
  `;
  try {
    const data = await client.fetch(query);
    console.log(data.listSection.sectionList)
    return data.listSection.sectionList || [];
  } catch (error) {
    console.error('Error fetching list section data:', error);
    return [];
  }
}
