import React, { useEffect, useState } from "react";
import sanityClient, { urlFor } from "../../../network/sanity/sanityClient";
import Header from "../../../components/Header";
import Header2 from "../../../components/Header2";
import Sidebar from "../../../components/Sidebar";
import Banner from "../../../components/Banner";
import Footer from "../../../components/Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SanityBlockContent from "@sanity/block-content-to-react";
import { getLangue, removeLangue, setLangue } from "../../../network/auth/Auth";
import { useParams } from "react-router-dom";
import RelatedBlog2 from "../../../components/blog/RelatedBlog2";
import CategoryBlock from "../../../components/blog/CategoryBlock";
import { PortableText } from "@portabletext/react";
import { components } from "../../../network/sanity/portableText";
import ReactPlayer from "react-player";
import SideBar2 from "../../../components/blog/SideBar2";

function OtherPage() {
  const [data, setData] = useState();
  const [clientLogos, setClientLogos] = useState();
  const location = useLocation();
  console.log("Données reçues ---------------:", location.state);
  const menuName = location.state;

  const [language, setLanguage] = useState(getLangue());
  const [paramsKey, setParamsKey] = useState();
  const [showHeader, setShowHeader] = useState(true);

  const hideMenu = () => {
    setShowHeader(false);
    console.log("good");
  };

  useEffect(() => {
    // Extraire la partie query de l'URL
    const searchParams = new URLSearchParams(location.search);

    // Récupérer la valeur de la clé 'key'
    const keyValue = searchParams.get("key");

    // Faire ce que vous voulez avec la valeur récupérée
    console.log("Valeur de la clé:", keyValue);

    if (keyValue !== "") {
      setShowHeader(false);
    }
    findPageByTitle(keyValue);
  }, [location.search]); // Exécuter l'effet à chaque changement de la partie query de l'URL

  useEffect(() => {
    const currentLanguage = getLangue();
    setLanguage(currentLanguage);
  }, []);

  // Fonction pour récupérer le texte original à partir d'un slug
  const unslugify = (slug) => {
    // Vérifiez si l'entrée est une chaîne, sinon fournissez une chaîne vide
    if (typeof slug !== "string") {
      console.error("Expected a string input, but got:", typeof slug, slug);
      return ""; // Retourne une chaîne vide ou une valeur par défaut
    }

    // Remplacer les tirets par des espaces
    return slug
      .replace(/-/g, " ") // Remplacer les tirets par des espaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Mettre en majuscule la première lettre de chaque mot
  };

  // Exemple d'utilisation
  const originalText = unslugify("this-is-a-slug");
  console.log(originalText); // Output: 'This Is A Slug'

  const findPageByTitle = async (keyValue) => {
    console.log("-------", keyValue);

    try {
      // Convertir le titre recherché en minuscule
      const lowerCaseTitle = keyValue;

      // Requête pour récupérer toutes les pages de type "otherPages"
      const query = `*[_type == "otherPages"][]`;

      // Exécution de la requête
      const results = await sanityClient.fetch(query);

      // Afficher les données pour vérifier la structure
      console.log("Fetched Results:", results);

      // Stocker l'objet correspondant trouvé
      let matchingSubCategory = null;

      // Parcourir les résultats pour trouver les sous-catégories correspondant au titre donné
      results.forEach((page) => {
        page.subCategories.forEach((subCategory) => {
          console.log("subcat", subCategory);
          if (subCategory._key && subCategory._key === keyValue) {
            matchingSubCategory = subCategory;
          }
        });
      });

      // Afficher l'objet correspondant trouvé
      if (matchingSubCategory) {
        console.log("Matching SubCategory:", matchingSubCategory);
        setData(matchingSubCategory);
        return matchingSubCategory;
      } else {
        console.log("No matching sub-category found");
        return null;
      }
    } catch (error) {
      console.error("Error fetching data from Sanity:", error);
      return null;
    }
  };

  const changeLangue = (newLang) => {
    setLangue(newLang);
    setLanguage(newLang);
  };

  const resetLangue = () => {
    removeLangue();
    setLanguage(getLangue());
  };

  const navigate = useNavigate();

  const changeActualite = (data) => {
    navigate("/other-details", { state: data });
  };

  const replaceUndefined = (imageUrl) => {
    if (!imageUrl) {
      console.error("Invalid image URL:", imageUrl);
      return null; // Retourne null si l'URL de l'image est invalide
    }

    // Remplacez "undefined" par "5pzop2m2" dans l'URL de l'image
    const newImageUrl = imageUrl.replace("undefined", "5pzop2m2");
    const newImageUrlF = newImageUrl.replace("undefined", "production");
    return newImageUrlF; // Retourne la nouvelle URL modifiée
  };
  const receiveDataFromChild = (data) => {
    console.log("data", data);
    //setData(data)
  };

  const getLanguage = (data) => {
    console.log("laguage ici", data);
    setLanguage(data);
  };
  useEffect(() => {
    sanityClient
      .fetch(
        ` *[_type == "home"]{
          partnersClientsSection{
            logos[]{
              "imageUrl": asset->url
            }
          }
        }[0].partnersClientsSection.logos `
      )
      .then((data) => {
        setClientLogos(data);
      })
      .catch(console.error);
    receiveDataFromChild();
  }, []);

  const serializers = {
    types: {
      block: (props) => {
        switch (props.node.style) {
          case "h1":
            return <h1>{props.children}</h1>;
          case "h2":
            return <h2>{props.children}</h2>;
          default:
            return <p>{props.children}</p>;
        }
      },
      image: ({ node }) => {
        return (
          <img
            src={`https://cdn.sanity.io/images/5pzop2m2/production/${
              node.asset._ref.split("-")[1]
            }.${node.asset._ref.split("-")[2]}`}
            alt={node.alt || "Image"}
            style={{ width: "100%" }}
          />
        );
      },
    },
  };

  return (
    <>
      <Header2
        sendDataToParent={receiveDataFromChild}
        sendLanguage={getLanguage}
        showHeader={showHeader}
        hideMenu={hideMenu}
        menuName={menuName?.nameMenu?.category}
      />
      <Sidebar />
      <Banner
        title={language === "fr" ? data?.title?.fr : data?.title?.en}
        backgroundImageUrl={
          data?.imageFond ? replaceUndefined(urlFor(data?.imageFond).url()) : ""
        }
        style={{ paddingBottom: "15px" }}
      />

      {data?.centerFields
        ? data?.centerFields?.subCategoryItems &&
          data?.centerFields?.subCategoryItems?.map((item, index) => (
            <div class="sidebar-page-container bg-white">
              <div class="auto-container">
                <div class="row clearfix">
                  <div class="content-side col-lg-8 col-md-12 col-sm-12">
                    <div class="blog-detail">
                      <div class="inner-box">
                        <div class="image">
                          <img
                            src={replaceUndefined(
                              urlFor(item?.imageBloc).url()
                            )}
                            alt=""
                          />
                        </div>

                        <div class="lower-content">
                          <ul class="post-meta">
                            <li>
                              <span class="icon flaticon-user-2"></span>
                              {language === "fr"
                                ? "Mission Permanente"
                                : "Permanent Mission"}
                            </li>
                          </ul>

                          <h4>
                            {language === "fr"
                              ? item.titlePost?.fr
                              : item.titlePost?.en}
                          </h4>

                          <PortableText
                            value={
                              language === "fr"
                                ? item?.description?.fr
                                : item?.description?.en
                            }
                            components={components(false)}
                            onMissingComponent={false}
                          />
                          {item.lien_video ? (
                            <ReactPlayer url={item.lien_video} />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="sidebar-side col-lg-4 col-md-12 col-sm-12">
                    <aside class="sidebar sticky-top">
                      {/* <RelatedBlog2 language={language} />
                      <CategoryBlock language={language} /> */}

                      <SideBar2 language={language} />
                    </aside>
                  </div>
                </div>
              </div>
            </div>
          ))
        : ""}

      {data?.leftFields
        ? data?.leftFields?.subCategoryItems &&
          data?.leftFields?.subCategoryItems?.map((item, index) => (
            <section
              key={index}
              className="choice-section style-two  pb-4"
              style={
                item?.background
                  ? {
                      background: item.background,
                      paddingTop: "40px !important",
                    }
                  : {}
              }
            >
              <div className="auto-container">
                <div className="row clearfix">
                  {/* Image Column */}
                  <div className="image-column col-lg-6 col-md-12 col-sm-12">
                    <div className="inner-column">
                      <div className="pattern-layer" style={{}} />
                      <div
                        className={item?.background ? "image pt-4" : "image "}
                        style={{ marginTop: "60px" }}
                      >
                        <img
                          src={replaceUndefined(urlFor(item?.imageBloc).url())}
                          alt=""
                          style={{ height: "100px !important;", width: "100%" }}
                        />
                      </div>
                    </div>
                  </div>
                  {/* Content Column */}
                  <div className="content-column col-lg-6 col-md-12 col-sm-12">
                    <div className="inner-column">
                      <div className="sec-title style-two">
                        <div className="separater" />
                        <h2>
                          {language === "fr"
                            ? item?.titlePost?.fr
                            : item?.titlePost?.en}
                        </h2>
                      </div>
                      <div className="text">
                        <PortableText
                          value={
                            language === "fr"
                              ? item?.description?.fr
                              : item?.description?.en
                          }
                          components={components(false)}
                          onMissingComponent={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ))
        : ""}

      {data?.rightFields
        ? data?.rightFields?.subCategoryItems &&
          data?.rightFields?.subCategoryItems?.map((item, index) => (
            <section
              key={index}
              className="choice-section style-two pt-0 pb-4"
              style={
                item?.background
                  ? {
                      background: item.background,
                      paddingTop: "40px!important",
                    }
                  : {}
              }
            >
              <div className="auto-container">
                <div className="row clearfix">
                  {/* Content Column */}
                  <div className="content-column col-lg-6 col-md-12 col-sm-12">
                    <div className="inner-column">
                      <div className="sec-title style-two">
                        <div className="separater" />
                        <h2>
                          {language === "fr"
                            ? item?.titlePost?.fr
                            : item?.titlePost?.en}
                        </h2>
                      </div>
                      <div className="text">
                        <PortableText
                          value={
                            language === "fr"
                              ? item?.description?.fr
                              : item?.description?.en
                          }
                          components={components(false)}
                          onMissingComponent={false}
                        />
                      </div>
                    </div>
                  </div>

                  {/* Image Column */}
                  <div className="image-column col-lg-6 col-md-12 col-sm-12">
                    <div className="inner-column">
                      <div className="pattern-layer" style={{}} />
                      <div
                        className={item?.background ? "image pt-4" : "image "}
                        style={{ marginTop: "60px!important" }}
                      >
                        <img
                          src={replaceUndefined(urlFor(item?.imageBloc).url())}
                          alt=""
                          style={{ height: "100px !important;", width: "100%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ))
        : ""}

      {data?.centerX3Fields ? (
        <section className="blog-page-section bg-white">
          <div className="auto-container">
            <div className="row clearfix">
              {data?.centerX3Fields?.subCategoryItems &&
                data?.centerX3Fields?.subCategoryItems?.map((item, index) => (
                  <div
                    key={index}
                    className="news-block style-two col-lg-4 col-md-6 col-sm-12"
                  >
                    <div className="inner-box">
                      <div className="image">
                        <Link onClick={() => changeActualite(item)}>
                          <a onClick={() => changeActualite(item)}>
                            {item?.imageBloc ? (
                              <img
                                src={replaceUndefined(
                                  urlFor(item?.imageBloc).url()
                                )}
                                style={{ height: "300px" }}
                              />
                            ) : (
                              ""
                            )}
                          </a>
                        </Link>
                      </div>
                      <div className="lower-content">
                        <ul className="post-meta">
                          <li>
                            <span className="icon flaticon-user-2"></span>
                            {language === "fr"
                              ? "Mission Permanente"
                              : "Permanent Mission"}
                          </li>
                        </ul>
                        <h4>
                          <a
                            className="two-line-text"
                            onClick={() => changeActualite(item)}
                          >
                            {language === "fr"
                              ? item?.titlePost?.fr
                              : item?.titlePost?.en}
                          </a>
                        </h4>
                        {/*   <div className="text two-line-text">
                          <PortableText
                            value={language === 'fr' ? item?.description?.fr : item?.description?.en}
                            components={components(false)}
                            onMissingComponent={false}
                          />
                        </div> */}
                        <div className="lower-box">
                          <div className="clearfix">
                            <div className="pull-left">
                              <a
                                className="read-more"
                                onClick={() => changeActualite(item)}
                              >
                                {language === "fr" ? "Voir Plus" : "Read More"}
                              </a>
                            </div>
                            <div className="pull-right">
                              <a
                                href={"#"}
                                className="plus-box flaticon-plus-symbol"
                              ></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </section>
      ) : (
        ""
      )}

      <section
        className="clients-section-three style-two"
        style={{
          backgroundImage: "url(assets/images/background/pattern-21.png)",
        }}
      >
        <div className="auto-container">
          <div className="row clearfix">
            <div className="clients-column col-lg-7 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="row clearfix">
                  {clientLogos &&
                    clientLogos?.map((logo, index) => (
                      <div
                        className="client-box col-lg-4 col-md-4 col-sm-12"
                        key={index}
                      >
                        <div className="image">
                          <a href="#">
                            <img
                              src={logo?.imageUrl}
                              alt={logo?.name}
                              style={{
                                height: "150px",
                                width: "150px",
                              }}
                            />
                          </a>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer language={language} />
    </>
  );
}

export default OtherPage;
