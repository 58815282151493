import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import Banner from "../../../components/Banner";
import Footer from "../../../components/Footer";
import { fetchPartnersClientsSectionData } from "../../../network/services/fetchHomeData";
import { getHeroSection, getAboutSection, getCounterSection, getVideoProjectSection, getSkillsSection, getChoiceSection, getRequestQuoteSection, getClientLogoSection, fetchListSectionData } from "../../../network/services/fetchDecouvrirCameroun"
import ClientSection from "../../../components/ClientSection";
import Loading from "../../../components/Loading";

function DecouvrirCameroun() {
  const [clientLogos, setClientLogos] = useState()
  const [heroSection, setHeroSection] = useState([]);
  const [aboutSection, setAboutSection] = useState({});
  const [counterSection, setCounterSection] = useState({});
  const [videoProjectSection, setVideoProjectSection] = useState({});
  const [skillsSection, setSkillsSection] = useState({});
  const [choiceSection, setChoiceSection] = useState({});
  const [requestQuoteSection, setRequestQuoteSection] = useState({});

  const [clientLogoSection, setClientLogoSection] = useState({});
  const lang = sessionStorage.getItem('langue')

  const [sections, setSections] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const sectionData = await fetchListSectionData();
      setSections(sectionData);
    };

    fetchData();
  }, [lang]);

  useEffect(() => {
    async function fetchData() {
      try {
        const heroData = await getHeroSection();
        const aboutData = await getAboutSection();
        const counterData = await getCounterSection();
        const videoProjectData = await getVideoProjectSection();
        const skillsData = await getSkillsSection();
        const choiceData = await getChoiceSection();
        const requestQuoteData = await getRequestQuoteSection();
        const clientLogoData = await getClientLogoSection();

        console.log(
          heroData,
          aboutData,
          counterData,
          videoProjectData,
          skillsData,
          choiceData,
          requestQuoteData,
          clientLogoData
        )


        setHeroSection(heroData);
        setAboutSection(aboutData);
        setCounterSection(counterData);
        setVideoProjectSection(videoProjectData);
        setSkillsSection(skillsData);
        setChoiceSection(choiceData);
        setRequestQuoteSection(requestQuoteData);
        setClientLogos(clientLogoData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchData();
  }, []);
  if (!heroSection || !aboutSection || !counterSection || !videoProjectSection || !skillsSection || !choiceSection || !requestQuoteSection || !clientLogos) {
    return <Loading />;
  }
  return (
    <>
      {heroSection ? (<>
        <Header />
        <Sidebar />

        {heroSection && (
          <Banner title={heroSection[0]?.textePrincipal} backgroundImageUrl={heroSection[0]?.imageFond.url} />
        )}
        <div>
          {sections && sections?.map((section, index) => (
            <section key={index} className="choice-section style-two">
              <div className="auto-container">
                <div className="row clearfix">
                  {section.position === 'left' ? (
                    <>
                      {/* Image Column */}
                      <div className="image-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner-column">
                          <div
                            className="pattern-layer"
                            style={{}}
                          />
                          <div className="image">
                            <img src={section.image} alt="" style={{ height: "100px !important;", width: "100%" }} />
                          </div>
                        </div>
                      </div>
                      {/* Content Column */}
                      <div className="content-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner-column">
                          <div className="sec-title style-two">
                            <div className="separater" />
                            <h2>{section.title}</h2>
                          </div>
                          <div className="text">
                            <p>{section.description}</p>
                          </div>
                          <a href="#" className="read-more">
                            Read More <span className="plus flaticon-plus-symbol" />
                          </a>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {/* Content Column */}
                      <div className="content-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner-column">
                          <div className="sec-title style-two">
                            <div className="separater" />
                            <h2>{section.title}</h2>
                          </div>
                          <div className="text">
                            <p>{section.description}</p>
                          </div>
                          <a href="#" className="read-more">
                            Read More <span className="plus flaticon-plus-symbol" />
                          </a>
                        </div>
                      </div>
                      {/* Image Column */}
                      <div className="image-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner-column">
                          <div
                            className="pattern-layer"
                            style={{ backgroundImage: `url(${section.image})` }}
                          />
                          <div className="image">
                            <img src={section.image} alt="" style={{ height: "100px !important;", width: "70%" }} />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </section>
          ))}
        </div>


        <ClientSection />
        <Footer />
      </>) : <Loading />}
    </>
  );
}

export default DecouvrirCameroun;