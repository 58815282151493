import client from './../sanity/sanityClient';

const lang = sessionStorage.getItem('langue') || 'fr';

export const fetchHero = async () => {
  const query = `*[_type == "Evenement"]{
    heroSection[]{
      "textePrincipal": textePrincipal.${lang},
      "texteSecondaire": texteSecondaire.${lang},
      "description": description.${lang},
      imageFond{
        asset->{
          _id,
          url
        }
      }
    }
  }[0]`;

  try {
    const data = await client.fetch(query);
    console.log(data.heroSection)
    return data.heroSection;
  } catch (error) {
    console.error('Error fetching hero section:', error);
    return null;
  }
};


export const fetchEvents = async () => {  
  const query = `*[_type == "Evenement"]{
    eventSection{
      title,
      events[]{
        date,
        artist,
        name,
        time,
        location,
        status
      }
    }
  }[0]`;

  try {
    const data = await client.fetch(query);
    console.log(data.eventSection)
    return data.eventSection;
  } catch (error) {
    console.error('Error fetching events:', error);
    return null;
  }
};

export const fetchRequestQuote = async () => {
  try {
    const query = `
      *[_type == "Evenement"][0]{
        "requestQuoteSection": requestQuoteSection{
          "titre": titre.${lang},
          "description": description.${lang},
          "callToActionText": callToActionText.${lang}
        }
      }
    `;

    const data = await client.fetch(query);
    return data.requestQuoteSection || null;
  } catch (error) {
    console.error('Error fetching request quote section data:', error.message);
    return null;
  }
};


export const fetchClientLogoNames = async () => {
  const query = `*[_type == "Evenement"][0]{
    "clientLogo": clientLogo{
      "name": name.${lang}
    }

  }`;

  try {
    const data = await client.fetch(query);
    console.log(data)
    return data.clientLogo.name
  } catch (error) {
    console.error('Error fetching client logo names:', error);
    return [];
  }
};


