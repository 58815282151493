import React from "react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import CarrouselItem from "./CarrouselItem";

import { Autoplay, Navigation, Pagination } from "swiper/modules";

const CarrouselBlog = () => {
  const data = [1, 2, 3];
  return (
    <div className="container container-carroussel-blog">
      <Swiper
        effect={"slide"}
        // direction={"vertical"}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        centeredSlides={true}
        autoplay={{
          delay: 6500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Navigation, Pagination]} //EffectFade,
        className="swiper-carroussel-blog"
      >
        {data?.map((item, index) => (
          <SwiperSlide key={index}>
            <CarrouselItem data={item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default CarrouselBlog;
