import client from './../sanity/sanityClient';


const lang = sessionStorage.getItem('langue'); // Récupérer la langue stockée ou utiliser le français par défaut
/**
 * Fetches the hero section data from Sanity.
 * @returns {Promise<Array|null>} The hero section data or null if an error occurs.
 */
export const fetchHeroSectionData = async () => {
  const query = `
  *[_type == "home"]{
    heroSection[]{
      "textePrincipal": textePrincipal.${lang},
      "texteSecondaire": texteSecondaire.${lang},
      "description": description.${lang},
      "boutonsAppelAction": boutonsAppelAction[]{
        "texte": texte.${lang},
        "url": url
      },
      "imageFond": imageFond.asset->{
        _id,
        url
      }
    }
  }[0]
  `;

  try {
    const result = await client.fetch(query);
    console.log(result.heroSection)
    return result.heroSection;
  } catch (error) {
    console.error('Error fetching hero section data:', error);
    return null;
  }
};

/**
 * Fetches the introduction section data from Sanity.
 * @returns {Promise<Object|null>} The introduction section data or null if an error occurs.
 */
export const fetchIntroductionSectionData = async () => {
  const query = `
  *[_type == "home"]{
    introductionSection{
      "titre": titre.${lang},
      "sousTitre": sousTitre.${lang}
    }
  }[0].introductionSection
  `;

  try {
    const result = await client.fetch(query);
    console.log(result);
    return result;
  } catch (error) {
    console.error('Error fetching introduction section data:', error);
    return null;
  }
};

/**
 * Fetches the information cards section data from Sanity.
 * @returns {Promise<Array|null>} The information cards section data or null if an error occurs.
 */
export const fetchInformationCardsSectionData = async () => {
  const query = `
  *[_type == "home"]{
    informationCardsSection{
      cartesInformatives[]{
        "nom": nom.${lang},
        "description": description.${lang},
        "imageUrl": image.asset->url
      }
    }
  }[0].informationCardsSection.cartesInformatives  
  `;

  try {
    const result = await client.fetch(query);
    console.log(result)
    return result;
  } catch (error) {
    console.error('Error fetching information cards section data:', error);
    return null;
  }
};

/**
 * Fetches the discover section data from Sanity.
 * @returns {Promise<Object|null>} The discover section data or null if an error occurs.
 */
export const fetchDiscoverSectionData = async () => {
  const query = `
  *[_type == "home"]{
    discoverSection{
      "titre": titre.${lang},
      "description": description.${lang},
      "backgroundUrl": background.asset->url,
      "media": media[]{
        "mediaUrl": asset->url,
        "mediaType": _type == "file",
        "mediaType": _type == "image"
      },
      "linkVideo": linkVideo
    }
  }[0].discoverSection  
  `;

  try {
    const result = await client.fetch(query);
    console.log(result)
    return result;
  } catch (error) {
    console.error('Error fetching discover section data:', error);
    return null;
  }
};

/**
 * Fetches the leadership section data from Sanity.
 * @returns {Promise<Object|null>} The leadership section data or null if an error occurs.
 */
export const fetchLeadershipSectionData = async () => {
  const query = `
  *[_type == "home"]{
    leadershipSection{
      "titre": titre.${lang},
      cartesLeaders[]{
        "nom": nom.${lang},
        "titre": titre.${lang},
        "imageUrl": image.asset->url,
        twitter,
        facebook,
        LinkedIn,
        google
      }
    }
  }[0].leadershipSection
  `;

  try {
    const result = await client.fetch(query);
    console.log(result);
    return result;
  } catch (error) {
    console.error('Error fetching leadership section data:', error);
    return null;
  }
};

/**
 * Fetches the partners/clients section data from Sanity.
 * @returns {Promise<Array|null>} The partners/clients section data or null if an error occurs.
 */
export const fetchPartnersClientsSectionData = async () => {
  const query = `
  *[_type == "home"]{
    partnersClientsSection{
      logos[]{
        "imageUrl": asset->url
      }
    }
  }[0].partnersClientsSection.logos  
  `;

  try {
    const result = await client.fetch(query);
    return result;
  } catch (error) {
    console.error('Error fetching partners/clients section data:', error);
    return null;
  }
};

export const fetchLowerText = async () => {
  try {
    const query = `
    *[_type == "home"][0] {
      "lowerText": {
        "text": lowerText.text.${lang},
        "linkText": lowerText.linkText.${lang},
        "linkUrl": lowerText.linkUrl
      }
    }
`;

    const data = await client.fetch(query);
    console.log(data);

    return data.lowerText || null;
  } catch (error) {
    console.error('Error fetching lower text data:', error.message);
    return null;
  }
};
