import React from "react";
import { getLangue } from "../network/auth/Auth";

function Loading() {
  const lang = getLangue();
  return (
    <div className="loading-area">
      <div className="loading-box"></div>

      <div className="loading-pic">
        <div className="cssload-loader">
          {lang === "fr" ? "Chargement" : "Loading"}
        </div>
      </div>
    </div>
  );
}

export default Loading;
