import client from "../sanity/sanityClient";
import { getLangue } from "../auth/Auth";
const lang = sessionStorage.getItem('langue') || 'fr'; // Récupère la langue stockée ou utilise le français par défaut

/**
 * Fetches the hero section data from Sanity for the contact page.
 * @returns {Promise<Object|null>} The hero section data or null if an error occurs.
 */
export const fetchContactHeroSectionData = async (language) => {
  try {
    const query = `
      *[_type == "contact"][0]{
        "heroSection": heroSection[]{
          "textePrincipal": textePrincipal.${language},
          "texteSecondaire": texteSecondaire.${language},
          "description": description.${language},
          "boutonsAppelAction": boutonsAppelAction[]{
            "texte": texte.${language},
            "url": url
          },
          "imageFond": imageFond.asset->{
            _id,
            url
          }
        }
      }
    `;

    const data = await client.fetch(query);
    console.log(data.heroSection);
    return data.heroSection[0] || null;
  } catch (error) {
    console.error('Error fetching contact hero section data:', error.message);
    return null;
  }
};

export const fetchContactFormSectionData = async (lang) => {
  try {
    const query = `
      *[_type == "contact"][0]{
        "contactFormSection": contactFormSection{
          "titre": titre.${lang},
          "description": description.${lang}
        }
      }
    `;

    const data = await client.fetch(query);
    return data.contactFormSection || null;
  } catch (error) {
    console.error('Error fetching contact form section data:', error.message);
    return null;
  }
};

export const fetchMapSectionData = async () => {
  try {
    const query = `
      *[_type == "contact"][0]{
        "mapSection": mapSection{
          "location": location,
          "address": address.${lang}
        }
      }
    `;

    const data = await client.fetch(query);
    return data.mapSection || null;
  } catch (error) {
    console.error('Error fetching map section data:', error.message);
    return null;
  }
};

export const fetchContactInfoSectionData = async (language) => {
  try {
    const query = `
      *[_type == "contact"][0]{
        "contactInfoSection": contactInfoSection{
          "titre": titre.${language},
          "infos": infos[]{
            "type": type.${language},
            "valeur": valeur.${language}
          }
        }
      }
    `;

    const data = await client.fetch(query);
    return data.contactInfoSection || null;
  } catch (error) {
    console.error('Error fetching contact info section data:', error.message);
    return null;
  }
};

export const fetchRequestQuoteSectionData = async () => {
  try {
    const query = `
      *[_type == "contact"][0]{
        "requestQuoteSection": requestQuoteSection{
          "titre": titre.${lang},
          "description": description.${lang},
          "callToActionText": callToActionText.${lang}
        }
      }
    `;

    const data = await client.fetch(query);
    return data.requestQuoteSection || null;
  } catch (error) {
    console.error('Error fetching request quote section data:', error.message);
    return null;
  }
};

