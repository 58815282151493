import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { filterBlogsBySearch, getDatePost2 } from "../../helpers/utils";
import {
  fetchBlogCategoryData,
  fetchBlogsData,
  fetchCategoriesBlogData,
} from "../../network/services/fetchBlog";
import { BlogH1, SkeletonBlogV } from "../inde";
import BlogV from "./BlogV";
import { CgEnter } from "react-icons/cg";

const SideBar2 = ({ language }) => {
  const lang = sessionStorage.getItem("langue");
  const { slugCategory } = useParams();
  const [data, setData] = useState(null);
  const [blogs, setBlogs] = useState([data?.blogs?.slice(0, 2)]);
  const [cats, setCats] = useState();

  // console.log("data ----------------------------- ", data);

  useEffect(() => {
    const fetchData = async () => {
      const blog = slugCategory
        ? await fetchBlogCategoryData(slugCategory, language)
        : await fetchBlogsData(language);
      console.log(blog);
      setData(blog);
      if (blog) {
        console.log("ici");
        if (blog?.blogs) {
          setBlogs(blog?.blogs?.slice(0, 2));
          console.log("ici no");
        } else {
          console.log("ici ici", blog?.slice(0, 2));
          setBlogs(blog?.slice(0, 2));
        }
      }
    };
    fetchData();
  }, []);

  const onSearch = (text) => {
    if (text) {
      setBlogs(filterBlogsBySearch(data, text));
    } else {
      setBlogs(data?.blogs?.slice(0, 2));
    }
  };
  return (
    // <>
    //   <div>
    //     <div class="sidebar-widget popular-posts">
    //       <div class="sidebar-title-two">
    //         <h3>
    //           {" "}
    //           {lang === "fr" ? "Document pertinent" : "Relevant Document"}
    //         </h3>
    //       </div>

    //       <>
    //         {blogs &&
    //           blogs?.map((blog, index) => (
    //             <div class="widget-content">
    //               <article class="post">
    //                 <figure class="post-thumb">
    //                   <a href="#">
    //                     {/* <img
    //                       src={blog?.image}
    //                       alt=""
    //                     /> */}
    //                     <section class="container row d-flex flex-nowrap flex-row justify-content-between align-items-center">
    //                       <img src="/assets/images/web-download-con-pearl-flyer.png"></img>
    //                       <a
    //                         href="#"
    //                         data-original-title=""
    //                         title=""
    //                         aria-describedby="tooltip623357"
    //                       ></a>
    //                     </section>

    //                   </a>
    //                 </figure>

    //                 <div class="text">
    //                   <a href={`/news/${blog?.slug.en.current}`}>
    //                     {blog?.title}
    //                   </a>
    //                 </div>

    //                 <div class="post-info">
    //                   {" "}
    //                   {blog?.date && getDatePost2(blog?.date)}
    //                 </div>
    //               </article>
    //             </div>
    //           ))}
    //       </>
    //     </div>
    //   </div>
    // </>
    <>
      <div className="oe_structure">
        <section className="pt0 pb0" data-name="Title">
          <div className="container">
            <div className="row s_nb_column_fixed">
              <div
                className="col-lg-12 s_title pt16 pb16"
                style={{
                  color: "green",
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                <h4 className="s_title_default">
                  {language === "en"
                    ? "RELEVANT MAIN COMMITTEE DOCUMENTS"
                    : "DOCUMENTS PERTINENTS DES COMMISSIONS PRINCIPALES"}
                </h4>
              </div>
            </div>
          </div>
        </section>

        <section
          className="custom-snippet document-list-wrapper"
          data-name="Document List"
          style={{ padding: "16px", backgroundColor: "#f8f9fa" }}
        >
          <h5
            className="title"
            style={{ marginBottom: "10px", color: "green" }}
          > 
          {language === "en"? "First Committee ": "Première Commission" }
           
          </h5>
          <span
            className="date o_default_snippet_text"
            style={{ marginLeft: "10px" }}
          >
            2012
          </span>

          <section
            className="container row d-flex flex-nowrap flex-row justify-content-between align-items-center"
            style={{ marginTop: "8px" }} // Reduced margin for tighter spacing
          >
            <img
              src="/assets/images/web-download-con-pearl-flyer.png"
              alt="Document"
              style={{ maxWidth: "100px", marginRight: "10px" }}
            />
            <a
              href="#"
              style={{
                color: "green",
                textDecoration: "none",
                marginRight: "10px",
              }}
            >
              BKB&nbsp;
            </a>
          </section>

          <section
            className="container row d-flex flex-nowrap flex-row justify-content-between align-items-center"
            style={{ marginTop: "8px" }} // Reduced margin for tighter spacing
          >
            <img
              src="/assets/images/web-download-con-pearl-flyer.png"
              alt="Document"
              style={{ maxWidth: "100px", marginRight: "10px" }}
            />
            <a href="#" style={{ color: "green", textDecoration: "none" }}>
            {language === "en"? "Declaration of Cameroon 925.9 KB ": "Déclaration du Cameroun 925.9 KB" }  
            </a>
          </section>
        </section>

        <section
          className="custom-snippet document-list-wrapper pt24"
          data-name="Document List"
          style={{ padding: "16px", backgroundColor: "#f8f9fa" }}
        >
          <h5
            className="title"
            style={{ marginBottom: "10px", color: "green" }}
          >
           {language === "en"? "Second committee": "Deuxième commission" } 
          </h5>
          <span className="date" style={{ marginLeft: "10px" }}>
            2012
          </span>

          <section
            className="container row d-flex flex-nowrap flex-row justify-content-between align-items-center"
            style={{ marginTop: "8px" }} // Reduced margin for tighter spacing
          >
            <img
              src="/assets/images/web-download-con-pearl-flyer.png"
              alt="Document"
              style={{ maxWidth: "100px", marginRight: "10px" }}
            />
            <a href="#" style={{ color: "green", textDecoration: "none" }}>
            {language === "en"? "Declaration of Mr Alain Wilfried BIYA 823.0 KB": 
            " Déclaration de M. Alain Wilfried BIYA 823.0 KB" } 
              
            </a>
          </section>

          <section
            className="container row d-flex flex-nowrap flex-row justify-content-between align-items-center"
            style={{ marginTop: "8px" }} // Reduced margin for tighter spacing
          >
            <img
              src="/assets/images/web-download-con-pearl-flyer.png"
              alt="Document"
              style={{ maxWidth: "100px", marginRight: "10px" }}
            />
            <a href="#" style={{ color: "green", textDecoration: "none" }}>
            {language === "en"? " Declaration of Mr. FOUDA NDI Joseph-Marie 1.7 MB": 
            "Déclaration de M. FOUDA NDI Joseph-Marie 1.7 MB" } 
             
            </a>
          </section>
        </section>

        <section
          className="custom-snippet document-list-wrapper pt24"
          data-name="Document List"
          style={{ padding: "16px", backgroundColor: "#f8f9fa" }}
        >
          <h5
            className="title"
            style={{ marginBottom: "10px", color: "green" }}
          >
            {language === "en"? "Third committee": 
            "Troisième commission" } 
          
          </h5>
          <span className="date" style={{ marginLeft: "10px" }}>
            2012
          </span>

          <section
            className="container row d-flex flex-nowrap flex-row justify-content-between align-items-center"
            style={{ marginTop: "8px" }} // Reduced margin for tighter spacing
          >
            <img
              src="/assets/images/web-download-con-pearl-flyer.png"
              alt="Document"
              style={{ maxWidth: "100px", marginRight: "10px" }}
            />
            <a href="#" style={{ color: "green", textDecoration: "none" }}>
            {language === "en"? "Point 54 statement of Cameroon 1.6 MB": 
            "Point 54 déclaration du Cameroun 1.6 MB" } 
          
              
            </a>
          </section>

          <section
            className="container row d-flex flex-nowrap flex-row justify-content-between align-items-center"
            style={{ marginTop: "8px" }} // Reduced margin for tighter spacing
          >
            <img
              src="/assets/images/web-download-con-pearl-flyer.png"
              alt="Document"
              style={{ maxWidth: "100px", marginRight: "10px" }}
            />
            <a href="#" style={{ color: "green", textDecoration: "none" }}>
            {language === "en"? "Item 54 Matters of peacekeeping operations 1.6 MB": 
            "Point 54 Questions des opérations de maintien de la paix 1.6 MB" } 
              
            </a>
          </section>
        </section>
      </div>
    </>
  );
};

export default SideBar2;
