import { format } from "date-fns";
import { enUS, fr } from "date-fns/locale";
import { useEffect, useRef } from "react";
const locales = {
  en: enUS,
  fr: fr,
};

const lang = sessionStorage.getItem("langue") || "fr";


export const onChangeInputFields = (setFields, key, text) => {
  setFields((prevFields) => ({
    ...prevFields,
    [key]: text,
  }));
};

export const getDatePost = (date) => {
  const d = new Date(`${date}`).toLocaleDateString("en-us", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  return d ?? "";
};

export const getDatePost2 = (date, lng = lang) => {
  const formattedDate = format(new Date(date), "MMM d, yyyy", {
    locale: locales[lng],
  });
  return formattedDate ?? "";
};
export const getDatePost3 = (date, lng = lang) => {
  const formattedDate = format(new Date(date), "MMMM d, yyyy", {
    locale: locales[lng],
  });
  return formattedDate ?? "";
};

export const useDocumentTitle = (title, prevailOnUnmount = false) => {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = title;

    // Clean up function
    return () => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle.current;
      }
    };
  }, [title, prevailOnUnmount]);
};

export const getColor = (color) => {
  // console.log("color --- ", color);
  return `${color}`.toString().trim().toLowerCase();
};

export const getBlogsByCategory = (blogs, slug) => {
  return blogs.filter(
    (blog) => blog.category && blog.category.slug["en"]["current"] === slug
  );
};

export const getBlogBySlug = (blogs, targetSlug) => {
  return blogs.find((blog) => blog.slug["en"]["current"] === targetSlug);
};

export const getDataBySlug = (data, targetSlug) => {
  return data.find((elt) => elt.slug["en"]["current"] === targetSlug);
};

export const getCategoryBySlug = (categories, targetSlug) => {
  return categories.find((cat) => cat.slug["en"]["current"] === targetSlug);
};

export const filterBlogsBySearch = (blogs, searchQuery) => {
  const sanitizedQuery = searchQuery.trim().toLowerCase();
  return blogs.filter((blog) =>
    blog.title.toLowerCase().includes(sanitizedQuery)
  );
};

export const blogs1 = [
  "Blog 1",
  "Blog 2",
  "Blog 3",
  "Blog 4",
  "Blog 5",
  "Blog 6",
  "Blog 7",
  "Blog 8",
  "Blog 9",
  "Blog 10",
  "Blog 11",
  "Blog 12",
  "Blog 13",
  "Blog 14",
  "Blog 15",
  "Blog 16",
  "Blog 17",
  "Blog 18",
  "Blog 19",
  "Blog 20",
  "Blog 20",
  "Blog 20",
  "Blog 20",
  "Blog 20",
  "Blog 20",
  "Blog 20",
  "Blog 20",
  "Blog 20",
  "Blog 20",
  "Blog 20",
  "Blog 20",
  "Blog 20",
  "Blog 20",
  "Blog 20",
  "Blog 20",
  "Blog 20",
  "Blog 20",
  "Blog 20",
  "Blog 20",
  "Blog 20",
];
