import React, { useEffect, useState } from "react";
import { fetchFooterData } from "../network/services/fetchFooterData";
import { fetchSettingsData } from "../network/services/fetchSettingsData";
import { getLangue } from "../network/auth/Auth";

function Footer(language) {
  const [footerData, setFooterData] = useState(null);
  const [logoUrl, setLogoUrl] = useState("");
  const [entrepriseDescription, setEntrepriseDescription] = useState("");
  const [contactInfo, setContactInfo] = useState(null);
  const [freeEstimatePhone, setFreeEstimatePhone] = useState("");
  const [newsletter, setNewsletter] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);

  const d = new Date();

  const handleInputChange = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    return regex.test(email);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(email);
    if (!validateEmail(email)) { 
      setError("Please enter a valid email address with a domain extension");
    } else {
      setError("Form submitted");
      // Submit form or perform further actions
      console.log("Form submitted");
    }
  };
  useEffect(() => {
    console.log("langue==", language?.language);
    const fetchData = async () => {
      try {
        const data = await fetchFooterData(language?.language);
        const settings = await fetchSettingsData();

        console.log(data);
        setFooterData(data);
        setLogoUrl(settings?.logoEntreprise.asset.url);
        setEntrepriseDescription(data?.descriptionEntreprise);
        setContactInfo(data?.coordonneesContact);
        setFreeEstimatePhone(data?.freeEstimatePhone);
        setNewsletter(data?.newsletter);
      } catch (error) {
        console.error("Error fetching footer data:", error);
      }
    };

    fetchData();
  }, []);

  // Fonction pour rendre les liens du footer
  const renderLinks = (links) => {
    if (!links || links.length === 0) return null;

    return links.map((link, index) => (
      <li key={index}>
        <a href={link.url}>{link.nom}</a>
      </li>
    ));
  };

  return (
    <>
      {" "}
      <footer className="main-footer">
        <div
          className="pattern-layer-one"
          style={{
            backgroundImage: "url(assets/images/background/pattern-6.png)",
          }}
        ></div>

        <div
          className="pattern-layer-two"
          style={{
            backgroundImage: "url(assets/images/background/pattern-7.png)",
          }}
        ></div>

        <div className="auto-container">
          <div className="widgets-section">
            <div className="row clearfix">
              <div className="big-column col-lg-6 col-md-12 col-sm-12">
                <div className="row clearfix">
                  <div className="footer-column col-lg-6 col-md-8 col-sm-12">
                    <div className="footer-widget logo-widget">
                      <div className="logo">
                        <a href="index-2.html">
                          <img src={logoUrl} alt="Logo" />
                        </a>
                      </div>

                      {entrepriseDescription && (
                        <div className="text entrepriseDescription">
                          {language?.language === "fr"
                            ? entrepriseDescription.fr
                            : entrepriseDescription.en}
                        </div>
                      )}

                      {/* <a href="#" className="read-more">
                        {language?.language === "fr"
                          ? "Voir Plus"
                          : "Read More"}
                      </a> */}
                    </div>
                  </div>

                  <div className="footer-column col-lg-6 col-md-4 col-sm-12">
                    <div className="footer-widget links-widget">
                      <h4>
                        {" "}
                        {language?.language === "fr"
                          ? "Liens utiles"
                          : "Useful Links"}
                      </h4>

                      <div className="row clearfix">
                        <div className="column col-lg-6 col-md-6 col-sm-12">
                          <ul className="list-link">
                            {footerData?.liensUtiles &&
                              footerData.liensUtiles?.map((item, index) => (
                                <li key={index}>
                                  <a href={item?.url} target="_blank">
                                    {item?.nom}
                                  </a>
                                </li>
                              ))}
                          </ul>
                        </div>

                        <div className="column col-lg-6 col-md-6 col-sm-12">
                          <ul className="list-link">
                            {footerData?.liensUtiles2 &&
                              footerData.liensUtiles2?.map((item, index) => (
                                <li key={index}>
                                  <a href={item?.url} target="_blank">
                                    {item?.nom}
                                  </a>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-column col-lg-3 col-md-&é col-sm-12">
                <div className="footer-widget request-widget">
                  <h4>
                    {newsletter &&
                      newsletter?.titleNewsletter[language.language ?? "fr"]}
                    {/* {language?.language === 'fr' ? "S'abonner à notre lettre d'information" : "Subscribe To Our Newsletter"} */}
                  </h4>

                  <div className="text">
                    {newsletter &&
                      newsletter?.descriptionNewsletter[
                        language.language ?? "fr"
                      ]}

                    {/* {language?.language === 'fr' ? " Restez en contact avec nous pour recevoir les dernières nouvelles" : " Stay in touch with us to get latest news"} */}
                  </div>
                  <br />
                  <div className="form-column col-lg-12 col-md-12 col-sm-12">
                    <div className="inner-column">
                      <div className="newsletter-form">
                        <form method="post" action="">
                          <div className="form-group">
                            <input
                              type="email"
                              name="email"
                              value={email}
                              onChange={handleInputChange}
                              placeholder=" "
                              required
                              disabled
                              style={{ color: "grey" }}
                            />

                            <button
                              // type="submit"
                              // onClick={handleSubmit}
                              className="theme-btn flaticon-big-envelope"
                            ></button>
                          </div>
                          {error}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="big-column col-lg-3 col-md-12 col-sm-12">
                <div className="row clearfix">
                  <div className="footer-column col-lg-12 col-md-12 col-sm-12">
                    <div className="footer-widget contact-widget">
                      <h4>
                        {language?.language === "fr"
                          ? "Prenez contact avec nous"
                          : "Get In Touch"}
                      </h4>

                      {footerData?.coordonneesContact ? (
                        <ul>
                          <li>
                            <span className="icon flaticon-maps-and-flags"></span>
                            {footerData?.coordonneesContact?.adresse}
                          </li>
                          <li>
                            <span className="icon flaticon-big-envelope"></span>
                            Email :{" "}
                            <a href="/contact">
                              {footerData?.coordonneesContact?.email}
                            </a>
                          </li>
                          <li>
                            <a></a>
                            <span className="icon flaticon-telephone"></span>
                            Phone : {footerData?.coordonneesContact?.telephone}
                            {""}
                            <br />
                          </li>
                        </ul>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="auto-container">
            <div className="row clearfix">
              <div className="column col-lg-6 col-md-12 col-sm-12">
                <div className="copyright" style={{ textAlign: "right" }}>
                  Copyright &copy; {d.getFullYear()} Delecam.{" "}
                  {language?.language === "fr"
                    ? "Tous droits réservés"
                    : "All rights reserved"}
                  {}.
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
