import { getLangue } from "../auth/Auth";
import client from "../sanity/sanityClient";

const lang = getLangue() || "fr";

export const fetchBlogPageData = async (langData) => {
  const query = `*[_type == "blogPage"][0]{
    "image":image.asset->url,
    "carrouselBlogs":carrousel[]->{_id,"slug":slug,"title":title.${langData},"category":category->{_id,"slug":slug,"title":title.${langData}},"image":image.asset->url,"smallDescription":smallDescription.${langData}},
    "recentsBlogs":*[_type == "blog"] | order(_updatedAt desc){_id,"slug":slug,"title":title.${langData},"category":category->{_id,"slug":slug,"title":title.${langData}},"image":image.asset->url,"smallDescription":smallDescription.${langData},date} [0...3],
    "popularBlogs":popular[]->{_id,"slug":slug,"title":title.${langData},"category":category->{_id,"slug":slug,"title":title.${langData}},"image":image.asset->url,"smallDescription":smallDescription.${langData},_updatedAt,_createdAt,date} | order(date desc),
    "trendingBlogs":trending[]->{_id,"slug":slug,"title":title.${langData},"category":category->{_id,"slug":slug,"title":title.${langData}},"image":image.asset->url,"smallDescription":smallDescription.${langData},_updatedAt,_createdAt,date}  | order(date desc),
    "categoriesBlogs":*[_type == "blogCategory"]{_id,"slug":slug,"title":title.${langData},"image":image.asset->url,"description":description.${langData},_updatedAt,_createdAt}  | order(title),
    "blogs":*[_type == "blog"]{_id,"slug":slug,"title":title.${langData},"category": category->{_id,"slug":slug,"title":title.${langData}},"image":image.asset->url,"smallDescription":smallDescription.${langData},_updatedAt,_createdAt,date,"description":description.${langData}}  | order(date desc),      
  }`;
  return await client.fetch(query);
};

export const fetchCategoriesBlogData = async (langData) => {
  const query = `*[_type == "blogCategory"]{
    _id,
    "slug":slug,
    "title":title.${langData},
    "image":image.asset->url,
    "description":description.${langData},
    _updatedAt,
    _createdAt
  } | order(title)`;
  return await client.fetch(query);
};

export const fetchBlogCategoryData = async (slug, langData) => {
  const query = `*[_type == "blogCategory" && slug.en.current == '${slug}'][0]{
    _id,
    "slug":slug,
    "title":title.${langData},
    "image":image.asset->url,
    "description":description.${langData},
    "blogs":*[_type == "blog" && references(^._id)]{_id,"slug":slug,"title":title.${langData},"category":category->{_id,"slug":slug,"title":title.${langData}},"image":image.asset->url,"smallDescription":smallDescription.${langData},_updatedAt,_createdAt,date,"description":description.${langData}}  | order(date desc),      
  }`;
  return await client.fetch(query);
};

export const fetchBlogData = async (slug, langData) => {
  const query = `*[_type == "blog" && slug.en.current == '${slug}'][0]{
    _id,
    "slug":slug,
    "title":title.${langData},
    "image":image.asset->url,
    "smallDescription":smallDescription.${langData},
    "description":description.${langData},
    "category":category->{_id,"slug":slug,"title":title.${langData}},
    date     
  }
    `;
  return await client.fetch(query);
};
export const fetchBlogsData = async (language) => {
  const query = `*[_type == "blog"]{
    _id,
    "slug":slug,
    "title":title.${language},
    "image":image.asset->url,
    "smallDescription":smallDescription.${language},
    "description":description.${language},
    "category":category->{_id,"slug":slug,"title":title.${language}},
    date     
  }
    `;
  return await client.fetch(query);
};



export const fetchTeamData = async (langData, teamCategoryId) => {
  try {
    console.log('Langue:', langData, 'Catégorie ID:', teamCategoryId);

    // Définition de la requête GROQ
    const query = `*[_type == "team" && teamcategory._ref == "${teamCategoryId}"]{
      _id,
      "firstname": firstname,
      "lastname": lastname,
      "image": image.asset->url,
      "smallDescription": smallDescription.${langData},
      "description": description.${langData},
      "poste": poste.${langData},
      "teamcategory": teamcategory->{_id, "slug": slug, "title": title.${langData}}
    }`;

    // Exécution de la requête avec le client Sanity
    const results = await client.fetch(query);

    // Affichage des résultats pour vérification
    console.log('Résultats de la requête:', results);

    return results; // Retour des données
  } catch (error) {
    console.error('Erreur lors de la récupération des données de l\'équipe:', error);
    throw new Error('Impossible de récupérer les données de l\'équipe');
  }
};


