import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import Banner from "../../../components/Banner";
import Footer from "../../../components/Footer";
import { Link } from "react-router-dom";
import { fetchAutoriteMaritineGalleryBlockData, fetchAutoriteMaritineHeroSectionData, fetchAutoriteMaritineRequestQuoteSectionData } from "../../../network/services/fetchAutoritemaritine";
import { fetchPartnersClientsSectionData } from "../../../network/services/fetchHomeData";

function AutoriteMaritime() {
  const [heroSection, setHeroSection] = useState(null);
  const [requestQuoteSection, setRequestQuoteSection] = useState(null);
  const [galleryBlock, setGalleryBlock] = useState([]);
  const [clientLogos, setClientLogos] = useState()

  useEffect(() => {
    const fetchData = async () => {      

      const heroSectionData = await fetchAutoriteMaritineHeroSectionData();
      setHeroSection(heroSectionData);
      
      const requestQuoteSectionData = await fetchAutoriteMaritineRequestQuoteSectionData();
      setRequestQuoteSection(requestQuoteSectionData);
      
      const serviceBlockData = await fetchAutoriteMaritineGalleryBlockData();
      setGalleryBlock(serviceBlockData);
      console.log(serviceBlockData)

      const clientLogosData = await fetchPartnersClientsSectionData();
      const limitedClientLogosData = clientLogosData.slice(0, 6); // Limite à 6 éléments
      setClientLogos(limitedClientLogosData);

    };

    fetchData();
  }, [])
  return (
    <>
      <Header />
      <Sidebar />
      {heroSection && (
        <Banner title={heroSection[0]?.textePrincipal} backgroundImageUrl={heroSection[0]?.imageFond.url} />
      )}

      <section
        class="gallery-section"
        style={{
          backgroundImage: "url(assets/images/background/pattern-1.png)",
        }}
      >
        <div class="auto-container">
          <div class="sec-title centered">
            <div class="separater"></div>

            <div class="title">{galleryBlock?.gallerySection?.subtitle}</div>

            <h2>{galleryBlock?.gallerySection?.title}</h2>
          </div>

          <div class="mixitup-gallery">
            <div class="filters clearfix">
              <ul class="filter-tabs filter-btns text-center clearfix">
                <li class="active filter" data-role="button" data-filter="all">
                  <span class="icon flaticon-folder"></span>All Cases
                </li>

                <li class="filter" data-role="button" data-filter=".interior">
                  <span class="icon flaticon-stair"></span>Interiors
                </li>

                <li class="filter" data-role="button" data-filter=".bridge">
                  <span class="icon flaticon-golden-gate-bridge"></span>Modern
                  Bridge
                </li>

                <li class="filter" data-role="button" data-filter=".building">
                  <span class="icon flaticon-skyscraper"></span>Buildings
                </li>

                <li class="filter" data-role="button" data-filter=".house">
                  <span class="icon flaticon-town"></span>Houses
                </li>
              </ul>
            </div>
            {/** A revoir ! */}
            <div class="filter-list row clearfix">
              <div class="gallery-block mix house bridge col-lg-4 col-md-6 col-sm-12">
                <div class="inner-box">
                  <div class="image">
                    <img src="assets/images/gallery/1.jpg" alt="" />

                    <div class="overlay-box">
                      <a
                        href="images/gallery/1.jpg"
                        data-fancybox="case"
                        data-caption=""
                        class="search-icon"
                      >
                        <span class="icon flaticon-plus"></span>
                      </a>

                      <div class="content">
                        <div class="category">Commercial</div>

                        <h4>
                          <a href="portfolio-single.html">Mechanical Trim</a>
                        </h4>

                        <a
                          href="portfolio-single.html"
                          class="read-more theme-btn"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="gallery-block mix interior col-lg-4 col-md-6 col-sm-12">
                <div class="inner-box">
                  <div class="image">
                    <img src="assets/images/gallery/2.jpg" alt="" />

                    <div class="overlay-box">
                      <a
                        href="images/gallery/2.jpg"
                        data-fancybox="case"
                        data-caption=""
                        class="search-icon"
                      >
                        <span class="icon flaticon-plus"></span>
                      </a>

                      <div class="content">
                        <div class="category">Commercial</div>

                        <h4>
                          <a href="portfolio-single.html">Mechanical Trim</a>
                        </h4>

                        <a
                          href="portfolio-single.html"
                          class="read-more theme-btn"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="gallery-block mix building interior col-lg-4 col-md-6 col-sm-12">
                <div class="inner-box">
                  <div class="image">
                    <img src="assets/images/gallery/3.jpg" alt="" />

                    <div class="overlay-box">
                      <a
                        href="images/gallery/3.jpg"
                        data-fancybox="case"
                        data-caption=""
                        class="search-icon"
                      >
                        <span class="icon flaticon-plus"></span>
                      </a>

                      <div class="content">
                        <div class="category">Commercial</div>

                        <h4>
                          <a href="portfolio-single.html">Mechanical Trim</a>
                        </h4>

                        <a
                          href="portfolio-single.html"
                          class="read-more theme-btn"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="gallery-block mix house interior col-lg-4 col-md-6 col-sm-12">
                <div class="inner-box">
                  <div class="image">
                    <img src="assets/images/gallery/4.jpg" alt="" />

                    <div class="overlay-box">
                      <a
                        href="images/gallery/4.jpg"
                        data-fancybox="case"
                        data-caption=""
                        class="search-icon"
                      >
                        <span class="icon flaticon-plus"></span>
                      </a>

                      <div class="content">
                        <div class="category">Commercial</div>

                        <h4>
                          <a href="portfolio-single.html">Mechanical Trim</a>
                        </h4>

                        <a
                          href="portfolio-single.html"
                          class="read-more theme-btn"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="gallery-block mix house building col-lg-4 col-md-6 col-sm-12">
                <div class="inner-box">
                  <div class="image">
                    <img src="assets/images/gallery/5.jpg" alt="" />

                    <div class="overlay-box">
                      <a
                        href="images/gallery/5.jpg"
                        data-fancybox="case"
                        data-caption=""
                        class="search-icon"
                      >
                        <span class="icon flaticon-plus"></span>
                      </a>

                      <div class="content">
                        <div class="category">Commercial</div>

                        <h4>
                          <a href="portfolio-single.html">Mechanical Trim</a>
                        </h4>

                        <a
                          href="portfolio-single.html"
                          class="read-more theme-btn"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="gallery-block mix house bridge interior building col-lg-4 col-md-6 col-sm-12">
                <div class="inner-box">
                  <div class="image">
                    <img src="assets/images/gallery/6.jpg" alt="" />

                    <div class="overlay-box">
                      <a
                        href="images/gallery/6.jpg"
                        data-fancybox="case"
                        data-caption=""
                        class="search-icon"
                      >
                        <span class="icon flaticon-plus"></span>
                      </a>

                      <div class="content">
                        <div class="category">Commercial</div>

                        <h4>
                          <a href="portfolio-single.html">Mechanical Trim</a>
                        </h4>

                        <a
                          href="portfolio-single.html"
                          class="read-more theme-btn"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="lower-text text-center">
            <a href="#" class="theme-btn btn-style-seven">
              View More <span class="plus-box flaticon-plus-symbol"></span>
            </a>
          </div>
        </div>
      </section>

      <section
        class="clients-section-three style-two"
        style={{
          backgroundImage: "url(assets/images/background/pattern-21.png)",
        }}
      >
        <div className="auto-container">
      <div className="row clearfix">
        {requestQuoteSection && (
          <div className="title-column col-lg-5 col-md-12 col-sm-12">
            <div className="inner-column">
              <div className="title-box">
                <div className="title">
                  <span className="separater"></span>
                  {requestQuoteSection.subTitre}
                </div>
                <h3>{requestQuoteSection.title}</h3>
                <div className="text">
                  {requestQuoteSection.description.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </div>
              </div>
              <a href="contact.html" className="read-more">
                Contact Now{" "}
                <span className="plus-box flaticon-plus-symbol"></span>
              </a>
            </div>
          </div>
        )}

            <div class="clients-column col-lg-7 col-md-12 col-sm-12">
              <div class="inner-column">
              <div className="row clearfix">
              {clientLogos && clientLogos.map((logo, index) => (
                <div class="client-box col-lg-4 col-md-4 col-sm-12">
                  <div class="image">
                    <a href="#">
                      <img src={logo.imageUrl} alt="" />
                    </a>
                  </div>
                </div>
              ))}
    </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default AutoriteMaritime;
