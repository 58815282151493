import React from "react";

const SkeletonBlogV = () => {
    return (
        <div className="w-100">
            <div className="d-flex flex-column w-100">
                <div className="skeleton skeleton-image"></div>
                <div className="pt-3">
                    <div className="skeleton skeleton-title"></div>
                    <div className="pt-1">
                        <div className="skeleton skeleton-text"></div>
                    </div>
                    <div className="skeleton skeleton-text"></div>
                    <div className="skeleton skeleton-text"></div>
                    <div className="d-flex justify-content-end font-italic">
                        <div className="skeleton skeleton-date"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SkeletonBlogV;
