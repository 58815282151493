import React, { Component, useEffect } from "react";

import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import listRoutes from "./routes/routes";
import {
  getUser,
  hasCredential,
  redirectionUrl,
  removeLastUrl,
  setLastUrl,
} from "./network/auth/Auth";
/* import {
  getUser,
  hasCredential,
  redirectionUrl,
  removeLastUrl,
  setLastUrl,
} from "./network/auth/Auth"; */


const PrivateRoute = ({ children, role, path }) => {
  console.log("PrivateRoute");
  console.log(children);
  console.log(role);
  //const auth = useAuth();
  let user = getUser();
  let defaultUrl = "/login";
  if (user) {
    console.log("PrivateRoute login");

    defaultUrl = redirectionUrl(user.role);
    console.log(user.role);
    console.log(role);
    console.log(defaultUrl);
  }
  const auth = hasCredential(user.role);

  const loc = window.location.href;
  //const location =path;
  const location = loc;
  if (!auth) {
    console.log("PrivateRoute non login");
    setLastUrl(location);
  } else {
    console.log("PrivateRoute last");
    removeLastUrl();
  }
  return auth ? children : <Navigate to={{ pathname: defaultUrl }} />;
};

class App extends Component {
  render() {
    //parse and arrange routes;
    let allRoutes = [];
    listRoutes.map(({ spaceName, secure, role, routes }) => {
      return routes.map(({ path, component }) => {
        return allRoutes.push({
          path: path,
          component: component,
          role: role,
          secure: secure,
        });
      });
    });

    //allRoutes is an array of objects where each object is constituted of a path, a component, a secure boolean and a role.
    const routeComponents = allRoutes.map(
      ({ path, component, secure, role }, key) => {
        return secure ? (
          <Route
            exact
            path={path}
            element={
              <PrivateRoute role={role} path={path}>
                {component}
              </PrivateRoute>
            }
            key={key}
          />
        ) : (
          <Route exact path={path} element={component} key={key} />
        );
      }
    );

    return (
      <>
        <Router>
          <Routes>{routeComponents}</Routes>
        </Router>
      </>
    );
  }
}

export default App;
