import client from './../sanity/sanityClient';


const lang = sessionStorage.getItem('langue'); // Récupérer la langue stockée ou utiliser le français par défaut
/**
 * Fetches the hero section data from Sanity.
 * @returns {Promise<Array|null>} The hero section data or null if an error occurs.
 */
export const fetchHeroSectionData = async () => {
  const query = `
  *[_type == "Presentation"]{
    heroSection[]{
      "textePrincipal": textePrincipal.${lang},
      "texteSecondaire": texteSecondaire.${lang},
      "description": description.${lang},
      "boutonsAppelAction": boutonsAppelAction[]{
        "texte": texte.${lang},
        "url": url
      },
      "imageFond": imageFond.asset->{
        _id,
        url
      }
    }
  }[0]
  `;
  console.log("result.heroSection")
  try {
    const result = await client.fetch(query);
    console.log(result.heroSection)
    return result.heroSection;
  } catch (error) {
    console.error('Error fetching hero section data:', error);
    return null;
  }
};

export async function getProjectSingleSection() {
  const query = `*[_type == "Presentation"][0]{
    "projectSingleSection": projectSingleSection{
      "titre": titre.${lang},
      "upperSection": upperSection.images[]{
        asset->{
          _id,
          url
        }
      },
      "imageLeft": imageLeft.asset->{
        _id,
        url
      },
      "lowerSection": lowerSection.${lang},
      "projectInfo": {
        "content": content.${lang},
        "infoList": infoList[]{
          "titre": titre.${lang},
          "value": value.${lang}
        }
      },
      "solutionBox": {
        "backgroundImage": solutionBox.backgroundImage.asset->url,
        "title": solutionBox.title.${lang},
        "heading": solutionBox.heading.${lang},
        "text": solutionBox.text.${lang},
        "buttonText": solutionBox.buttonText.${lang},
        "buttonLink": buttonLink
      },
      "morePosts": {
        "prevLink": prevLink,
        "nextLink": nextLink
      }
    }
  }`;

  const data = await client.fetch(query);
  console.log(data.projectSingleSection);
  return data.projectSingleSection || {};
}

export const fetchPresentationRequestQuoteSectionData = async () => {
  try {
    const query = `
      *[_type == "Presentation"][0]{
        "requestQuoteSection": requestQuoteSection{
          "title": title.${lang},
          "sousTitre": sousTitre.${lang},
          "description": description.${lang}
        }
      }
    `;

    const data = await client.fetch(query);
    return data.requestQuoteSection || null;
  } catch (error) {
    console.error('Error fetching ActiviteMission request quote section data:', error.message);
    return null;
  }
};