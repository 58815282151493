import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { filterBlogsBySearch, getDatePost2 } from "../../helpers/utils";
import {
  fetchBlogCategoryData,
  fetchBlogsData,
  fetchCategoriesBlogData
} from "../../network/services/fetchBlog";
import { BlogH1, SkeletonBlogV } from "../inde";
import BlogV from "./BlogV";

const RelatedBlog2 = ({ language }) => {
  const lang = sessionStorage.getItem("langue");
  const { slugCategory } = useParams();
  const [data, setData] = useState(null);
  const [blogs, setBlogs] = useState([data?.blogs?.slice(0, 2)]);
  const [cats, setCats] = useState();

  // console.log("data ----------------------------- ", data);

  useEffect(() => {
    const fetchData = async () => {
      const blog = slugCategory
        ? await fetchBlogCategoryData(slugCategory, language)
        : await fetchBlogsData(language);
      console.log(blog)
      setData(blog);
      if (blog) {
        console.log("ici")
        if (blog?.blogs) {
          setBlogs(blog?.blogs?.slice(0, 2)); console.log("ici no")
        }
        else { console.log("ici ici", blog?.slice(0, 2)); setBlogs(blog?.slice(0, 2)) };
      }

    };
    fetchData();
  }, []);




  const onSearch = (text) => {
    if (text) {
      setBlogs(filterBlogsBySearch(data, text));
    } else {
      setBlogs(data?.blogs?.slice(0, 2));
    }
  };
  return (
    <>



      <div >
    


        <div class="sidebar-widget popular-posts">
          <div class="sidebar-title-two">
            <h3> {language === "fr"
              ? "Postes associés"
              : "Related Posts"
            }</h3>
          </div>

          <>
            {blogs &&
              blogs?.map((blog, index) =>
                <div class="widget-content">
                  <article class="post">
                    <figure class="post-thumb">
                      <a href={`/news/${blog?.slug.en.current}`}>
                        <img
                          src={blog?.image}
                          alt=""
                        />
                      </a>
                    </figure>

                    <div class="text">
                      <a href={`/news/${blog?.slug.en.current}`}>
                        {blog?.title}
                      </a>
                    </div>

                    <div class="post-info"> {blog?.date && getDatePost2(blog?.date)}</div>
                  </article>

                </div>
              )}
          </>

        </div>


      </div>
    </>
  );
};

export default RelatedBlog2;
