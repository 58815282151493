import client from './../sanity/sanityClient';

// Fonction pour récupérer les données de la section de demande de devis avec traduction
export const fetchRequestQuoteSectionData = async (lang = 'fr') => {
  try {
    const query = `
      *[_type == "ClientSection"][0]{
        "requestQuoteSection": requestQuoteSection{
          "title": title.${lang},
          "sousTitre": sousTitre.${lang},
          "description": description.${lang}
        }
      }
    `;
    const data = await client.fetch(query);
    return data.requestQuoteSection || null;
  } catch (error) {
    console.error('Error fetching request quote section data:', error.message);
    return null;
  }
};

// Fonction pour récupérer les données de la section des clients/partners avec traduction
export const fetchPartnersClientsSectionData = async (lang = 'fr') => {
  try {
    const query = `
      *[_type == "ClientSection"][0]{
        "clientLogo": clientLogo[]{
          "name": name,
          "image": image.asset->url
        }
      }
    `;
    const data = await client.fetch(query);
    console.log(data)
    return data.clientLogo || null;
  } catch (error) {
    console.error('Error fetching partners/clients section data:', error.message);
    return null;
  }
};
