import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import Banner from "../../../components/Banner";
import Footer from "../../../components/Footer";
import { fetchPartnersClientsSectionData } from "../../../network/services/fetchHomeData";
import { fetchActiviteRepresentantHeroSectionData, fetchActiviteRepresentantRequestQuoteSectionData, fetchActiviteRepresentantServiceBlockData } from "../../../network/services/fetchActiviteRepresentant";
import ClientSection from "../../../components/ClientSection";
import Loading from "../../../components/Loading";

function ActiviteRepresentant() {

  const [heroSection, setHeroSection] = useState(null);
  const [requestQuoteSection, setRequestQuoteSection] = useState(null);
  const [serviceBlock, setServiceBlock] = useState([]);
  const [clientLogos, setClientLogos] = useState()

  const lang = sessionStorage.getItem('langue');

  useEffect(() => {
    const fetchData = async () => {

      const heroSectionData = await fetchActiviteRepresentantHeroSectionData();
      setHeroSection(heroSectionData);

      const requestQuoteSectionData = await fetchActiviteRepresentantRequestQuoteSectionData();
      setRequestQuoteSection(requestQuoteSectionData);

      const serviceBlockData = await fetchActiviteRepresentantServiceBlockData();
      setServiceBlock(serviceBlockData);
      console.log(serviceBlockData)

      const clientLogosData = await fetchPartnersClientsSectionData();
      const limitedClientLogosData = clientLogosData.slice(0, 6); // Limite à 6 éléments
      setClientLogos(limitedClientLogosData);

    };

    fetchData();
  }, [])

  return (
    <>
      {heroSection ? (<>
        <Header />
        <Sidebar />
        {heroSection && (
          <Banner title={heroSection[0].textePrincipal} backgroundImageUrl={heroSection[0].imageFond.url} />
        )}

        <section className="services-page-section">
          <div className="auto-container">
            <div className="row clearfix">
              {serviceBlock.map((service, index) => (
                <div className={`service-block-three ${index % 2 === 1 ? 'style-two' : ''} col-lg-4 col-md-6 col-sm-12`} key={index}>
                  <div className="inner-box">
                    <div className="line-one" style={{ zIndex: 1 }}></div>
                    <div className="line-two" style={{ zIndex: 1 }}></div>
                    <div className="">
                      <img src={service.image.url} alt={service?.title} style={{
                        height: "350px",
                        width: "96%"
                      }} />
                    </div>
                    <h4>
                      <a href="#" className="two-line-text">
                        {service?.title}
                      </a>
                    </h4>
                    <div className="text two-line-text">{service?.description}</div>
                    <a className="read-more" href="#">
                      Read More <span className="arrow fa fa-angle-double-right"></span>
                    </a>
                  </div>
                </div>
              ))}
              {/* <div className="content-side col-lg-12 col-md-12 col-sm-12">
            <div className="our-shops">
              <div className="shop-section">
                <div className="our-shops">
                  <ul className="styled-pagination text-center">
                    <li className="prev">
                      <a href="#">
                        <span className="fa fa-angle-left"></span>
                      </a>
                    </li>
                    <li>
                      <a href="#">1</a>
                    </li>
                    <li>
                      <a href="#" className="active">
                        2
                      </a>
                    </li>
                    <li>
                      <a href="#">3</a>
                    </li>
                    <li className="next">
                      <a href="#">
                        <span className="fa fa-angle-right"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
            </div>
          </div>
        </section>

        <ClientSection />
        <Footer />
      </>) : <Loading />}
    </>
  );
}

export default ActiviteRepresentant;
