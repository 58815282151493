// fetchHeaderData.js
import client from './../sanity/sanityClient';

export const fetchSettingsData = async () => {
  try {
    const query = `*[_type == "settings"]{
      logoEntreprise{
        asset->{
          _id,
          url
        }
      },
      iconicWords,
      reseauxSociaux,
      nomEntreprise,
      adresse,
      telephone,
      email
    }[0]`;
    const settingsData = await client.fetch(query);
    return settingsData;
  } catch (error) {
    console.error(error.message)
  }
};

