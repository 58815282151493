import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import Banner from "../../../components/Banner";
import Footer from "../../../components/Footer";
import { fetchBannerSection, fetchBlog, fetchClients, fetchHero, fetchSidebar } from "../../../network/services/fetchPaysMembre";
import { fetchPartnersClientsSectionData } from "../../../network/services/fetchHomeData";

function PaysMembre() {
  const [bannerSection, setBannerSection] = useState(null);
  const [heroSection, setHeroSection] = useState(null);
  const [blogSection, setBlogSection] = useState(null);
  const [sidebarSection, setSidebarSection] = useState(null);
  const [clientsSection, setClientsSection] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [clientLogos, setClientLogos] = useState()

  const lang = sessionStorage.getItem('langue');

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetching data for each section
        const heroData = await fetchHero();
        const blogData = await fetchBlog();
        const sidebarData = await fetchSidebar();
        const clientsData = await fetchClients();
        const bannerData = await fetchBannerSection();

        const clientLogosData = await fetchPartnersClientsSectionData();
        const limitedClientLogosData = clientLogosData.slice(0, 6); // Limite à 6 éléments
        setClientLogos(limitedClientLogosData);

        // Setting state for each section
        setHeroSection(heroData);
        setBlogSection(blogData);
        setSidebarSection(sidebarData);
        setClientsSection(clientsData);
        setBannerSection(bannerData);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array to run only once on mount

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <Header />
      <Sidebar />
      {bannerSection && (
        <Banner title={bannerSection[0]?.textePrincipal} backgroundImageUrl={bannerSection[0]?.imageFond?.asset?.url} />
      )}

      <div className="sidebar-page-container bg-white">
        <div className="auto-container">
          <div className="row clearfix">
            {/* Afficher la section héros */}
            <div className="content-side col-lg-8 col-md-12 col-sm-12">
              {heroSection && (
                <div className="blog-detail">
                  <div className="inner-box">
                    <div className="image">
                      <img src={heroSection.image.asset.url} alt="" />
                    </div>
                    <div className="lower-content">
                      <ul className="post-meta">
                        <li>
                          <span className="icon flaticon-chat-bubble"></span> Comments
                        </li>
                        <li>
                          <span className="icon flaticon-user-2"></span> {heroSection.author || 'Unknown Author'}
                        </li>
                        <li>
                          <span className="icon flaticon-timetable"></span> {heroSection.date || 'Unknown Date'}
                        </li>
                      </ul>
                      <h4>{heroSection.title || 'Title'}</h4>
                      {heroSection.content && heroSection.content.map((paragraph, index) => (
                        <p key={index}>{paragraph}</p>
                      ))}
                      {blogSection.galleryImages && (
                        <div className="galley-column">
                          <div className="row clearfix">
                            {blogSection.galleryImages.map((image, index) => (
                              <div key={index} className="column col-lg-6 col-md-6 col-sm-12">
                                <div className="image">
                                  <img src={image.asset.url} alt="" />
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                      {blogSection.tags && (
                        <div className="post-share-options">
                          <div className="post-share-inner clearfix">
                            <div className="tags">
                              <span className="fa fa-tags"></span>
                              {blogSection.tags.map((tag, index) => (
                                <a key={index} href="#">{tag[lang]}</a>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/*   <div className="social-sharing">
                    <div className="share-inner clearfix">
                      <div className="pull-left">
                        <ul className="social-box">
                          <li className="facebook">
                            <a href="#">
                              <span className="icon fa fa-facebook"></span> facebook{" "}
                              <span className="number">0</span>
                            </a>
                          </li>
                          <li className="tweet">
                            <a href="#">
                              <span className="icon fa fa-twitter"></span> Tweet{" "}
                              <span className="number">0</span>
                            </a>
                          </li>
                          <li className="pinterest">
                            <a href="#">
                              <span className="icon fa fa-pinterest"></span> Pinterest{" "}
                              <span className="number">0</span>
                            </a>
                          </li>
                          <li className="dribbble">
                            <a href="#">
                              <span className="icon fa fa-dribbble"></span> Dribbble{" "}
                              <span className="number">0</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="pull-right">
                        <div className="share">
                          <span className="icon fa fa-share"></span>
                          0 <br /> Shares
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              )}
            </div>
            {/* Afficher la barre latérale */}
            <div className="sidebar-side col-lg-4 col-md-12 col-sm-12">
              {sidebarSection && (
                <aside className="sidebar sticky-top">
                  {/* Afficher les widgets de la barre latérale */}
                  {sidebarSection.relevantDocuments && (
                    <div className="sidebar-widget category-widget">
                      <div className="widget-content">
                        <div className="sidebar-title-two">
                          <h3>RELEVANT DOCUMENTS</h3>
                        </div>
                        <ul className="cat-list">
                          {sidebarSection.relevantDocuments.map((document, index) => (
                            <li key={index} className="clearfix">
                              <a href={document.link}>
                                {document.title} <span>{document.count}</span>
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}
                  {sidebarSection.otherCommittees && (
                    <div className="sidebar-widget popular-posts">
                      <div className="sidebar-title-two">
                        <h3>OTHER COMMITTEES</h3>
                      </div>
                      <div className="widget-content">
                        {sidebarSection.otherCommittees.map((committee, index) => (
                          <article key={index} className="post">
                            <figure className="post-thumb">
                              <a href={committee.link}>
                                <img src={committee.image.asset.url} alt="" />
                              </a>
                            </figure>
                            <div className="text">
                              <a href={committee.link}>
                                {committee.title}
                              </a>
                            </div>
                            <div className="post-info">{committee.date}</div>
                          </article>
                        ))}
                      </div>
                    </div>
                  )}
                  {sidebarSection.gallery && (
                    <div className="sidebar-widget gallery-widget">
                      <div className="sidebar-title-two">
                        <h3>gallery</h3>
                      </div>
                      <div className="gallery-outer clearfix">
                        {sidebarSection.gallery.map((image, index) => (
                          <figure key={index} className="image">
                            <a
                              href={image.asset.url}
                              className="lightbox-image"
                              title="Image Title Here"
                            >
                              <img src={image.asset.url} alt="" />
                            </a>
                          </figure>
                        ))}
                      </div>
                    </div>
                  )}
                </aside>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default PaysMembre;
