import React, { useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom";
import { fetchHeaderData } from "../network/services/fetchHeaderData";
import { urlFor } from "../network/services/urlImage";
import { fetchSettingsData } from "../network/services/fetchSettingsData";
import sanityClient from "../network/sanity/sanityClient";
import { useTranslation } from "react-i18next";
import Breadcrumb from "./Breadcrumb";
function Header() {
  const [headerData, setHeaderData] = useState(null);
  const [settings, setSettings] = useState(null);
  const lang = sessionStorage.getItem("langue"); // Récupérer la langue stockée ou utiliser le français par défaut
  const [isHovered, setIsHovered] = useState(false);
  const location = useLocation();
  const [isHomePage, setIsHomePage] = useState([]);
  const [isScrolled, setIsScrolled] = useState([]);
const gradientStart = "#ed6ea0";
  const gradientEnd = "#ec8c69";
  // useEffect(() => {

  //   sanityClient
  //     .fetch(
  //       `*[_type == "header"][0]{
  //       logo,
  //       "descriptionLieu": descriptionLieu.${lang ? lang : "fr"},
  //       navigationPrincipale[]{
  //         nom,
  //         url
  //       },
  //       informationsDeContact[]{
  //         appeler,
  //         horairesOuverture,
  //         email
  //       }
  //     }`
  //     )
  //     .then((data) => {
  //       setHeaderData(data);
  //     })
  //     .catch(console.error);

  //   sanityClient
  //     .fetch(
  //       `*[_type == "settings"]{
  //         logoEntreprise{
  //           asset->{
  //             _id,
  //             url
  //           }
  //         },
  //         iconicWords,
  //         reseauxSociaux,
  //         nomEntreprise,
  //         adresse,
  //         telephone,
  //         email
  //       }[0]`
  //     )
  //     .then((data) => {
  //       console.log("header data", data);
  //       setSettings(data);
  //     })
  //     .catch(console.error);
  // }, []);
  const buttonStyles = {
    backgroundColor: isHovered ? "#FFBB1B" : "#1B5E20", // Change these to your colors
    transition: "background-color 0.3s",
    padding: "10px 20px", // Ensure padding remains consistent
    border: "none", // Remove any borders that might cause resizing
    display: "inline-block", // Ensure the button behaves consistently in layout
    textAlign: "center", // Make sure text alignment is consistent
    verticalAlign: "middle", // Keep the content centered vertically
    position: "relative",
  };
  const bodyStyle = {
    display: "flex",
    height: "100vh",
    padding: "0 5rem",
    boxSizing: "border-box",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    fontFamily: "'Open Sans', sans-serif",
  };

  const menuStyle = {
    backgroundImage: `linear-gradient(to right, ${gradientStart} 0%, ${gradientEnd} 100%)`,
    borderRadius: "0.5rem",
    textTransform: "uppercase",
    boxShadow: "inset 0.1rem 0.1rem 0.5rem rgba(0, 0, 0, 0.15)",
  };

  const linkStyle = {
    color: "#fff",
    textDecoration: "none",
    fontWeight: 300,
    padding: "2rem 0",
    fontSize: "1.6vw",
    textAlign: "center",
    opacity: 0.7,
    transition: "250ms ease all",
  };


  const changeLanguage = (e) => {
    sessionStorage.setItem("langue", e.target.value);
    window.location.reload();
  };

  if (!headerData) return <div></div>;

  return (
    <>
      <header className="main-header ">
        <div className="header-top">
          <div className="auto-container">
            <div className="clearfix">
              <div className="top-left clearfix">
                <div className="text">
                  <span className="icon flaticon-chat-bubble"></span>{" "}
                  {headerData?.descriptionLieu}
                </div>
              </div>

              {/* <div className="top-right pull-right">
                <ul className="nav-list">
                  <li>
                    <select
                      className="language-selector"
                      value={lang}
                      onChange={(e) => changeLanguage(e)}
                    >
                      <option value="en">English</option>
                      <option value="fr">Français</option>
                      <option value="es">Español</option>
                    </select>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>

        <div className="header-upper">
          <div className="auto-container">
            <div className="clearfix">
              <div className="pull-left logo-box">
                <div className="logo">
                  <a href="/">
                    {headerData?.logo ? (
                      <img
                        src={urlFor(headerData.logo).url()}
                        alt=""
                        title=""
                      />
                    ) : (
                      ""
                    )}
                  </a>
                </div>
              </div>

              <div className="pull-right upper-right clearfix">
                <div className="upper-column info-box">
                  <div className="icon-box">
                    <li>
                      {" "}
                      <a>
                        <span className="fa fa-phone"></span>
                      </a>{" "}
                    </li>
                  </div>

                  <ul>
                    <li>
                      <strong>
                        {lang === "fr"
                          ? "Appelez-nous à tout moment"
                          : lang === "en"
                          ? "Call Us Anytime"
                          : "Llámenos en cualquier momento"}
                      </strong>
                    </li>

                    {headerData?.informationsDeContact.map((info, index) => (
                      <li key={index}>{info.appeler}</li>
                    ))}
                  </ul>
                </div>

                <div className="upper-column info-box">
                  <div className="icon-box">
                    <a>
                      <span className="fa fa-clock-o"></span>
                    </a>
                  </div>

                  <ul>
                    <li>
                      <strong>
                        {lang === "fr"
                          ? "Heures d'ouverture"
                          : lang === "en"
                          ? "Opening Time"
                          : "Horario de apertura"}
                      </strong>
                    </li>

                    {headerData?.informationsDeContact &&
                      headerData?.informationsDeContact?.map((info, index) => (
                        <li key={index}>{info.horairesOuverture}</li>
                      ))}
                  </ul>
                </div>
                <div className="upper-column info-box">
                  <div className="icon-box">
                    <Link to="/contact">
                      <a style={{ position: "inline-flex", top: "13px" }}>
                        <span className="fa fa-envelope-o"></span>
                      </a>
                    </Link>
                  </div>

                  <ul>
                    <li>
                      <strong>
                        {lang === "fr"
                          ? "Envoyez-nous un e-mail"
                          : lang === "en"
                          ? "Email Us Now"
                          : "Envíenos un correo electrónico"}
                      </strong>
                    </li>

                    {headerData?.informationsDeContact &&
                      headerData?.informationsDeContact?.map((info, index) => (
                        <li key={index}>{info.email}</li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isHomePage && !isScrolled && (
          <div className="header-lower">
            <div className="auto-container">
              <div className="nav-outer clearfix">
                <div className="mobile-nav-toggler" >
                  {/* <span className="icon flaticon-menu-2"></span> */}
                </div>

                <nav className="main-menu show navbar-expand-md">
                  {/* <div className="navbar-header">
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="icon-bar"></span>

                    <span className="icon-bar"></span>

                    <span className="icon-bar"></span>
                  </button>
                </div> */}

                  <div
                    className="navbar-collapse collapse clearfix"
                    id="navbarSupportedContent"
                  >
                    <ul className="navigation clearfix">
                      <li>
                        <Link to="/">
                          {lang === "fr"
                            ? "Accueil"
                            : lang === "en"
                            ? "Home"
                            : "Inicio"}
                        </Link>
                      </li>
                      <li className="dropdown position-static">
                        <a href="#">
                          {lang === "fr"
                            ? "Mission permanente"
                            : lang === "en"
                            ? "Permanent Mission"
                            : "Misión permanente"}
                        </a>
                        <ul>
                          <li>
                            <Link to="/presentation-mission">
                              {lang === "fr"
                                ? "Présentation"
                                : lang === "en"
                                ? "Presentation"
                                : "Presentación"}
                            </Link>
                          </li>
                          <li>
                            <Link to="/activite-mission">
                              {lang === "fr"
                                ? "Activités de la Mission"
                                : lang === "en"
                                ? "Mission Activities"
                                : "Actividades de la Misión"}
                            </Link>
                          </li>
                          <li>
                            <Link to="/activite-representant">
                              {lang === "fr"
                                ? "Activités du Représentant"
                                : lang === "en"
                                ? "Representative Activities"
                                : "Actividades del Representante"}
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown position-static">
                        <a href="#">
                          {lang === "fr"
                            ? "Cameroun"
                            : lang === "en"
                            ? "Cameroon"
                            : "Camerún"}
                        </a>
                        <ul>
                          <li>
                            <Link to="/decouvrir-cameroun">
                              {lang === "fr"
                                ? "Découvrir le Cameroun"
                                : lang === "en"
                                ? "Discover Cameroon"
                                : "Descubrir Camerún"}
                            </Link>
                          </li>
                          <li>
                            <Link to="/tourisme-cameroun">
                              {lang === "fr"
                                ? "Tourisme au Cameroun"
                                : lang === "en"
                                ? "Tourism in Cameroon"
                                : "Turismo en Camerún"}
                            </Link>
                          </li>
                          <li>
                            <Link to="/organisation-administrative">
                              {lang === "fr"
                                ? "Organisation Administrative"
                                : lang === "en"
                                ? "Administrative Organization"
                                : "Organización Administrativa"}
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown position-static">
                        <a href="#">
                          {lang === "fr"
                            ? "Nations Unies"
                            : lang === "en"
                            ? "United Nations"
                            : "Naciones Unidas"}
                        </a>
                        <ul>
                          <li>
                            <a href="/pays-membre">
                              {lang === "fr"
                                ? "Pays Membres"
                                : lang === "en"
                                ? "Member States"
                                : "Estados Miembros"}
                            </a>
                          </li>
                          <li>
                            <a href="/dossiers-speciaux">
                              {lang === "fr"
                                ? "Dossiers spéciaux"
                                : lang === "en"
                                ? "Special Dossiers"
                                : "Expedientes especiales"}
                            </a>
                          </li>
                          <li>
                            <a href="/traite-convention">
                              {lang === "fr"
                                ? "Traités et Conventions"
                                : lang === "en"
                                ? "Treaties and Conventions"
                                : "Tratados y Convenciones"}
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="/autorite-maritime">
                          {lang === "fr"
                            ? "Autorité maritime"
                            : lang === "en"
                            ? "Maritime Authority"
                            : "Autoridad marítima"}
                        </Link>
                      </li>
                      <li>
                        <Link to="/actualite">
                          {lang === "fr"
                            ? "Actualités"
                            : lang === "en"
                            ? "News"
                            : "Noticias"}
                        </Link>
                      </li>
                      <li>
                        <Link to="/evenement">
                          {lang === "fr"
                            ? "Événements"
                            : lang === "en"
                            ? "Events"
                            : "Eventos"}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>

                <div className="options-box clearfix">
                  <div className="search-box-outer">
                    <div className="search-box-btn">
                      <span className="fa fa-search"></span>
                    </div>
                  </div>

                  <div className="button-box">
                    <Link
                      to="/contact"
                      className="theme-btn btn-style-one"
                      style={buttonStyles}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}  
                    >
                      
                      <span className="txt">
                        <i className="fa fa-phone"></i>
                      </span>
                    </Link>
                  </div>

                  {/* <div className="nav-btn navSidebar-button">
                  <span className="icon flaticon-menu"></span>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="mobile-menu">
          <div className="menu-backdrop"></div>

          <div className="close-btn">
            <span className="icon flaticon-multiply"></span>
          </div>

          <nav className="menu-box">
            <div className="nav-logo">
              <a href="index-2.html">
                <img src="assets/images/logo.png" alt="" title="" />
              </a>
            </div>

            <div className="menu-outer"></div>
          </nav>
        </div>
      </header>
    </>
  );
}

export default Header;
