import React from "react";

import { Link } from "react-router-dom";
import Breadcrumb from "./Breadcrumb";

function Banner({ title, backgroundImageUrl }) {
  return (
    <>
      <section
        className="page-title"
        style={{
          backgroundImage: `url(${backgroundImageUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "100vh",
          width: "100%",
        }}
      >
        <div class="auto-container">
          <h1 class=" two-line-text">{title}</h1>
        </div>
      </section>
    </>
  );
}

export default Banner;
