import React from "react";
import { NavLink } from "react-router-dom";
import { getDatePost2 } from "../../helpers/utils";
import { urlFor } from "../../network/sanity/sanityClient";
import { productImage } from "../../assets";

const BlogV = ({ data }) => {
  console.log("date --- ", data?.date);
  return (
    <div className="w-100">
      <div className="d-flex flex-column w-100">
        <div className="row">
          <div className="col-12 col-md-4">
            <NavLink
              to={`/new/${data?.category?.slug?.en?.current}/${data?.slug.en.current}`}
            >
              <img src={data?.image} className="blog-h1-image" alt="" />
            </NavLink>
          </div>
          <div className="col-12 col-md-8">
            <div className="">
              <NavLink
                to={`/new/${data?.category?.slug?.en?.current}/${data?.slug.en.current}`}
                className="title-blog"
              >
                {data?.title}
              </NavLink>
              <div className="pt-1">
                <NavLink
                  to={`/category-new/${data?.category?.slug?.en?.current}`}
                  className="title-category-blog"
                >
                  {data?.category?.title}
                </NavLink>
              </div>
              {/* <p className="card-text desc-categ-blog">
                {data?.smallDescription}
              </p> */}
              <div className="d-flex justify-content-end font-italic">
                <span className="text-muted">
                  {data?.date && getDatePost2(data?.date)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogV;
