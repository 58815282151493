import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Banner from "../../../components/Banner";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import BlogV from "../../../components/blog/BlogV";
import { SkeletonBlogV, TagBlog } from "../../../components/inde";
import {
  fetchBlogCategoryData,
  fetchBlogsData,
} from "../../../network/services/fetchBlog";

const BlogCategory = () => {
  const lang = sessionStorage.getItem("langue");
  const { slug } = useParams();
  const [category, setCategory] = useState(null);
  const [blogs, setBlogs] = useState([]);
  // console.log("category --- *********************************** ", category);
  const listRef = useRef(null);
  const n = 4,
    see = 6;
  const [visibleCount, setVisibleCount] = useState(see);

  useEffect(() => {
    const fetchData = async () => {
      const category = await fetchBlogCategoryData(slug);
      setCategory(category);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (category) {
      setBlogs(category?.blogs);
    }
  }, [category]);

  const showMore = () => {
    setVisibleCount((prevCount) => prevCount + see);
  };

  const showLess = () => {
    setVisibleCount((prevCount) =>
      prevCount - see >= see ? prevCount - see : see
    );
    if (listRef.current) {
      listRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      <Banner title={"News"} backgroundImageUrl={category?.image} />
      <div className="container-blog">
        <div className="container pb-4">
          <TagBlog />
          {category && (
            <>
              <div className="d-flex pt-2 pb-1">
                <span className="blog-category-title">{category?.title}</span>
              </div>
              <div className="pt-4" ref={listRef}>
                {blogs?.length > 0 ? (
                  <div className="grid-recents pt-2">
                    {blogs?.slice(0, visibleCount)?.map((item, index) => (
                      <BlogV key={index} data={item} />
                    ))}
                  </div>
                ) : (
                  <div className="h-100 d-flex flex-row justify-content-center pb-5">
                    <span className="no-blog">
                      {lang === "fr"
                        ? "Pas d'actualites pour le moment"
                        : lang === "en"
                          ? "No news for the moment"
                          : "Sin noticias por el momento"}
                    </span>
                  </div>
                )}
              </div>

              {blogs && blogs?.length > see && (
                <div className="d-flex container-see  justify-content-center">
                  {visibleCount < blogs?.length && (
                    <button className="see-button" onClick={showMore}>
                      {lang === "fr"
                        ? "Voir plus"
                        : lang === "en"
                          ? "View more"
                          : "Más información"}
                    </button>
                  )}
                  {visibleCount > see && (
                    <button className="see-button" onClick={showLess}>
                      {lang === "fr"
                        ? "Voir moins"
                        : lang === "en"
                          ? "View less"
                          : "Ver menos"}
                    </button>
                  )}
                </div>
              )}
            </>
          )}
          {!category && (
            <div className="pt-4">
              <div className="grid-recents pt-2">
                {[...Array(n)].map((_, index) => (
                  <SkeletonBlogV key={index} />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BlogCategory;
