import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import routes from "../routes/routes";
import "./breadcrumb.css";
import { getLangue } from "../network/auth/Auth";

const BreadcrumbNews0 = ({ language }) => {
  const location = useLocation();
  //const [language, setLanguage] = useState(getLangue());
  const pathParts = location.pathname.split("/").filter(Boolean);

  const findRouteName = (path) => {
    for (const routeGroup of routes) {
      const matchingRoute = routeGroup.routes.find(
        (route) => route.path === path
      );
      if (matchingRoute) return matchingRoute.name;
    }
    return null;
  };

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb bread-design">
          <li className="breadcrumb-item">
            <Link to="/"> {language === "fr" ? "Accueil" : "Home"}</Link>
          </li>

          <li className="breadcrumb-item">
            {language === "fr" ? ` Actualités` : ` News `}
          </li>
        </ol>
      </nav>
    </div>
  );
};

export default BreadcrumbNews0;
