import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import Banner from "../../../components/Banner";
import Footer from "../../../components/Footer";
import { fetchContactFormSectionData, fetchContactHeroSectionData, fetchContactInfoSectionData, fetchMapSectionData, fetchRequestQuoteSectionData } from "../../../network/services/fetchContactData";
import { fetchPartnersClientsSectionData } from "../../../network/services/fetchHomeData";
import Header2 from "../../../components/Header2";
import { getLangue } from "../../../network/auth/Auth";

function Contact() {

  const [heroSectionData, setHeroSectionData] = useState(null);
  const [contactFormSectionData, setContactFormSectionData] = useState(null);
  const [mapSectionData, setMapSectionData] = useState(null);
  const [contactInfoSectionData, setContactInfoSectionData] = useState(null);
  const [requestQuoteSectionData, setRequestQuoteSectionData] = useState(null);
  const [clientLogos, setClientLogos] = useState()

  const [language, setLanguage] = useState(getLangue());
  const getLanguage = async (data) => {
    console.log("laguage ici", data);
    setLanguage(data);
    const heroData = await fetchContactHeroSectionData(data);
    setHeroSectionData(heroData);

    const contactInfoData = await fetchContactInfoSectionData(data);
    setContactInfoSectionData(contactInfoData);


    const formSectionData = await fetchContactFormSectionData(data);
    setContactFormSectionData(formSectionData);
    console.log("rrrrrrrrrrfffffffffffffffffff----------", formSectionData)

  };

  useEffect(() => {
    const fetchData = async () => {
      const heroData = await fetchContactHeroSectionData(language);
      setHeroSectionData(heroData);

      const formSectionData = await fetchContactFormSectionData(language);
      setContactFormSectionData(formSectionData);
      console.log("rrrrrrrrrrfffffffffffffffffff----------", formSectionData)
      const mapData = await fetchMapSectionData();
      setMapSectionData(mapData);

      const contactInfoData = await fetchContactInfoSectionData(language);
      setContactInfoSectionData(contactInfoData);


      const requestQuoteData = await fetchRequestQuoteSectionData();
      setRequestQuoteSectionData(requestQuoteData);

      const clientLogosData = await fetchPartnersClientsSectionData();
      const limitedClientLogosData = clientLogosData.slice(0, 6); // Limite à 6 éléments
      setClientLogos(limitedClientLogosData);

    };

    fetchData();
  }, [])

  const generateMapUrl = (location, address) => {
    const { lat, lng } = location;
    const encodedAddress = encodeURIComponent(address);
    console.log(lat)
    // URL Google Maps avec paramètres dynamiques pour la latitude et la longitude
    return `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d805184.6331292129!2d${lng}!3d${lat}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0:0x0!2z${lat},${lng}!5e0!3m2!1sen!2s!4v${encodedAddress}!5m2!1sen!2s`;
  };


  const getTitleTranslation = () => {
    switch (language) {
      case 'fr':
        return 'Informations de Contact';
      case 'en':
        return 'Contact Information';
      case 'es':
        return 'Información de Contacto';
      default:
        return 'Contact Information';
    }
  };

  const getInfoLabel = (type) => {
    switch (type.toLowerCase()) {
      case 'phone':
        return language === 'fr' ? 'Appelez-nous' : 'Call Us On';
      case 'email':
        return language === 'fr' ? 'Envoyez-nous un email' : 'Email Us';
      case 'opening hours':
        return language === 'fr' ? 'Heures d\'ouverture' : 'Opening Hours';
      case 'address':
        return language === 'fr' ? 'Adresse' : 'Address';
      default:
        return type;
    }
  };


  const receiveDataFromChild = (data) => {
    console.log("data", data);
    //setData(data)
  };









  return (
    <>
      <Header2 sendDataToParent={receiveDataFromChild} sendLanguage={getLanguage} />
      <Sidebar />
      {heroSectionData && (
        <Banner title={heroSectionData.textePrincipal} backgroundImageUrl={heroSectionData.imageFond.url} />
      )}


      <section className="contact-info-section bg-white">
        <div className="auto-container">
          <div className="row clearfix">


            <div className="info-column col-lg-12 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="row clearfix">
                  {contactInfoSectionData && contactInfoSectionData.infos.map((info, index) => (
                    <div key={index} className="contact-column col-lg-4 col-md-4 col-sm-12">
                      <div className="inner">
                        <div className=""></div>
                        <ul>
                          <li>
                            <strong>{info.type}</strong>
                          </li>
                          <li>{info.valeur}</li>
                        </ul>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section class="contact-page-section">
        <div class="auto-container">
          <div class="row clearfix">
            <div class="title-column col-lg-4 col-md-12 col-sm-12">
              <div class="inner-column">
                <div class="sec-title style-two">
                  <div class="separater"></div>

                  <div class="title">{contactFormSectionData && contactFormSectionData.sousTitre}</div>

                  <h2>{contactFormSectionData && contactFormSectionData.titre}</h2>

                  <div class="text">
                    {contactFormSectionData && contactFormSectionData.description}{" "}
                  </div>
                </div>
              </div>
            </div>

            <div class="form-column col-lg-8 col-md-12 col-sm-12">
              <div class="inner-column">
                <div class="contact-form">
                  <form
                    method="post"
                    action="https://expert-themes.com/html/builder-expert/sendemail.php"
                    id="contact-form"
                  >
                    <div class="row clearfix">
                      <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                        <input
                          type="text"
                          name="username"
                          placeholder="Name"
                          required
                        />
                      </div>

                      <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          required
                        />
                      </div>

                      <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                        <input
                          type="text"
                          name="phone"
                          placeholder="Phone"
                          required
                        />
                      </div>

                      <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                        <input
                          type="text"
                          name="subject"
                          placeholder="Subject"
                          required
                        />
                      </div>

                      <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                        <textarea
                          name="message"
                          placeholder="Message"
                        ></textarea>
                      </div>

                      <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                        <button
                          class="theme-btn btn-style-seven"
                          type="submit"
                          name="submit-form"
                        >
                          {language === 'fr' ? 'Contactez-nous' : 'Contact Now'}{" "}
                          <span class="plus-box flaticon-plus-symbol"></span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="contact-map-section">
        <div class="outer-container">
          <div class="map-boxed">
            <div class="map-outer">
              {mapSectionData && (
                <iframe
                  src={generateMapUrl(mapSectionData.location, mapSectionData.address)}
                  allowFullScreen=""
                  title="Google Maps"
                ></iframe>
              )}
            </div>
          </div>
        </div>
      </section>



      <section
        className="clients-section-three style-two"
        style={{ backgroundImage: 'url(assets/images/background/pattern-21.png)' }}
      >
        <div className="auto-container">
          <div className="row clearfix">


            <div className="clients-column col-lg-7 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="row clearfix">
                  {clientLogos && clientLogos?.map((logo, index) => (
                    <div className="client-box col-lg-4 col-md-4 col-sm-12" key={index}>
                      <div className="image">
                        <a href="#">
                          <img src={logo?.imageUrl} alt={logo?.name} style={{
                            height: "150px",
                            width: "150px"
                          }} />
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <Footer language={language} />
    </>
  );
}

export default Contact;
