// VideoPlayer.js
import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";

// VideoModal Component
function VideoModal(props) {
  return (
    <div>
      <ModalVideo
        channel="youtube" // Fixed typo from "chanel" to "channel"
        autoplay
        isOpen={props.isOpen}
        videoId="WqB9qxfr-zI"
        onClose={props.close}
      />
    </div>
  );
}

// PlayButton Component
function PlayButton(props) {
  return (
    <button
      style={{
        backgroundColor: "#28a745", // Bootstrap primary button color
        color: "white",
        padding: "10px 20px",
        border: "none",
        borderRadius: "5px",
        fontSize: "16px",
        fontWeight: "bold",
        cursor: "pointer",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      }}
      onClick={props.click}
    >
      Play Video
    </button>
  );
}

// Combined VideoPlayer Component
function VideoPlayer({ handleChange, isOpen }) {
  const handleChange2 = () => {
    handleChange();
  };

  return (
    <div className="VideoPlayer">
      <VideoModal isOpen={isOpen} close={handleChange2} />
     
    </div>
  );
}

export default VideoPlayer;
