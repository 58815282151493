import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import Banner from "../../../components/Banner";
import Footer from "../../../components/Footer";
import { fetchTraiteConventionsData } from "../../../network/services/fetchTraiteConvention";
import Loading from "../../../components/Loading";

function TraitesConventions() {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchTraiteConventionsData().then((data) => {
      setData(data[0]); // Assumes only one document in the dataset
      console.log(data);
    });
  }, []);

  if (!data) return <Loading />;
  return (
    <>
      <Header />
      <Sidebar />
      <Banner
        title={data.bannerSection[0].textePrincipal}
        backgroundImageUrl={data.bannerSection[0].imageFond.asset.url}
      />

      <div className="sidebar-page-container bg-white">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="content-side col-lg-8 col-md-12 col-sm-12">
              <div className="blog-detail">
                <div className="inner-box">
                  <div className="image">
                    <img src="assets/images/resource/news-13.jpg" alt="" style={{ height: "440px" }} />
                  </div>
                  <div className="lower-content">
                    <ul className="cat-list">
                      {data?.blogPosts && data.blogPosts?.length ? data?.blogPosts.map((post, index) => (
                        <li key={index} className="clearfix">
                          <a href={post.link}>
                            {index + 1}. {post?.title || 'Titre non disponible'} ratifiée {post.date || 'Date non disponible'}
                          </a>
                        </li>
                      )) : <li>Aucun article de blog disponible</li>}
                    </ul>
                    <div className="post-share-options">
                      <div className="post-share-inner clearfix">
                        <div className="tags">
                          <span className="fa fa-tags"></span>
                          <a href="#">Auto</a> <a href="#">Automation</a>
                          <a href="#">Partnering</a>
                          <a href="#">Provider</a>
                          <a href="#">Resources</a>
                          <a href="#">Solutions</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="sidebar-side col-lg-4 col-md-12 col-sm-12">
              <aside className="sidebar sticky-top">
                <div className="sidebar-widget popular-posts">
                  <div className="sidebar-title-two">
                    <h3>Convention - Date De Ratification</h3>
                  </div>
                  <div className="widget-content">
                    {data.sidebarLinks?.length ? data.sidebarLinks.map((link, index) => (
                      <article key={index} className="post pl-0" style={{ minHeight: "auto" }}>
                        <div className="text">
                          <a href={link.link}>
                            {link?.title || 'Titre non disponible'}
                          </a>
                        </div>
                        <div className="post-info">{link.date || 'Date non disponible'}</div>
                      </article>
                    )) : <p>Aucun lien de la barre latérale disponible</p>}
                  </div>
                </div>
                <div className="sidebar-widget sidebar-gallery">
                  <div className="sidebar-title-two">
                    <h3>Our partners</h3>
                  </div>
                  <div className="widget-content">
                    <div className="outer clearfix">
                      {data.clientLogos?.length ? data.clientLogos.map((logo, index) => (
                        <div key={index} className="image">
                          <a href="#">
                            <img src={logo.asset?.url || 'Image non disponible'} alt="" />
                          </a>
                        </div>
                      )) : <p>Aucun logo de client disponible</p>}
                    </div>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TraitesConventions;
