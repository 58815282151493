import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import Banner from "../../../components/Banner";
import Footer from "../../../components/Footer";
import { Link } from "react-router-dom";
import { fetchActualityHeroSectionData, fetchActualityRequestQuoteSectionData, fetchActualityServiceBlockData } from "../../../network/services/fetchActualityData";
import { fetchPartnersClientsSectionData } from "../../../network/services/fetchHomeData";
import Loading from "../../../components/Loading";
import Header2 from "../../../components/Header2";

function Actualite() {
  const [heroSection, setHeroSection] = useState(null);
  const [requestQuoteSection, setRequestQuoteSection] = useState(null);
  const [serviceBlock, setServiceBlock] = useState();
  const [clientLogos, setClientLogos] = useState()

  useEffect(() => {
    const fetchData = async () => {

      const heroSectionData = await fetchActualityHeroSectionData();
      setHeroSection(heroSectionData);

      const requestQuoteSectionData = await fetchActualityRequestQuoteSectionData();
      setRequestQuoteSection(requestQuoteSectionData);

      const serviceBlockData = await fetchActualityServiceBlockData();
      setServiceBlock(serviceBlockData);
      console.log(serviceBlockData)

      const clientLogosData = await fetchPartnersClientsSectionData();
      const limitedClientLogosData = clientLogosData.slice(0, 6); // Limite à 6 éléments
      setClientLogos(limitedClientLogosData);

    };

    fetchData();
  }, [])
  return (
    <>
      {serviceBlock ? (<>
        <Header2 />
        <Sidebar />
        {heroSection && (
          <Banner title={heroSection[0].textePrincipal} backgroundImageUrl={heroSection[0].imageFond.url} />
        )}

        <section className="blog-page-section bg-white">
          <div className="auto-container">
            <div className="row clearfix">
              {serviceBlock && serviceBlock?.map((item, index) => (
                <div key={index} className="news-block style-two col-lg-4 col-md-6 col-sm-12">
                  <div className="inner-box">
                    <div className="image">
                      <Link to={item.readMoreLink}>
                        <a href="#">
                          <img src={item.image.url} alt={item.title} style={{ height: "300px" }} />
                        </a>
                      </Link>
                    </div>
                    <div className="lower-content">
                      <ul className="post-meta">
                        <li>
                          <span className="icon flaticon-chat-bubble"></span>{item.comments ? item.comments : '0'} Comments
                        </li>
                        <li>
                          <span className="icon flaticon-user-2"></span>{item.author}
                        </li>
                        <li>
                          <span className="icon flaticon-timetable"></span>{new Date(item.date).toLocaleDateString()}
                        </li>
                      </ul>
                      <h4>
                        <Link to={item.readMoreLink}>
                          <a href="#" className="two-line-text">{item.title}</a>
                        </Link>
                      </h4>
                      <div className="text two-line-text">
                        {item?.text}
                      </div>
                      <div className="lower-box">
                        <div className="clearfix">
                          <div className="pull-left">
                            <a href={item.readMoreLink} className="read-more">
                              Read More
                            </a>
                          </div>
                          <div className="pull-right">
                            <a href={item.readMoreLink} className="plus-box flaticon-plus-symbol"></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* <ul className="styled-pagination text-center">
                    <li className="prev">
                        <a href="#">
                            <span className="fa fa-angle-left"></span>
                        </a>
                    </li>
                    <li>
                        <a href="#">1</a>
                    </li>
                    <li>
                        <a href="#" className="active">
                            2
                        </a>
                    </li>
                    <li>
                        <a href="#">3</a>
                    </li>
                    <li className="next">
                        <a href="#">
                            <span className="fa fa-angle-right"></span>
                        </a>
                    </li>
                </ul> */}
          </div>
        </section >

        <section
          class="clients-section-three style-two"
          style={{
            backgroundImage: "url(assets/images/background/pattern-21.png)",
          }}
        >
          <div className="auto-container">
            <div className="row clearfix">
              {requestQuoteSection && (
                <div className="title-column col-lg-5 col-md-12 col-sm-12">
                  <div className="inner-column">
                    <div className="title-box">
                      <div className="title">
                        <span className="separater"></span>
                        {requestQuoteSection?.sousTitre}
                      </div>
                      <h3>{requestQuoteSection?.title}</h3>
                      <div className="text">
                        {typeof requestQuoteSection?.description === 'string' ? requestQuoteSection?.description.split('\n').map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        )) : ""}
                      </div>
                    </div>
                    <a href="contact.html" className="read-more">
                      Contact Now{" "}
                      <span className="plus-box flaticon-plus-symbol"></span>
                    </a>
                  </div>
                </div>
              )}

              <div class="clients-column col-lg-7 col-md-12 col-sm-12">
                <div class="inner-column">
                  <div className="row clearfix">
                    {clientLogos && clientLogos.map((logo, index) => (
                      <div class="client-box col-lg-4 col-md-4 col-sm-12">
                        <div class="image">
                          <a href="#">
                            <img src={logo.imageUrl} alt="" />
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </>) : <Loading />}
    </>
  );
}

export default Actualite;
