import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import Banner from "../../../components/Banner";
import Footer from "../../../components/Footer";
import { useLocation } from 'react-router-dom';
import sanityClient, { urlFor } from "../../../network/sanity/sanityClient";
import { SanityClient } from "@sanity/client";
import Header2 from "../../../components/Header2";
import { getLangue, removeLangue, setLangue } from "../../../network/auth/Auth";
import RelatedBlog2 from "../../../components/blog/RelatedBlog2";
import CategoryBlock from "../../../components/blog/CategoryBlock";
function ActualiteDetails() {
  const lang = sessionStorage.getItem('langue');
  const [data, setData] = useState()
  const location = useLocation();
  console.log('Données reçues :', location.state);
  const item = location.state;
  const [clientLogos, setClientLogos] = useState()
  const [language, setLanguage] = useState(getLangue());
  useEffect(() => {
    sanityClient.fetch(
      ` *[_type == "home"]{
          partnersClientsSection{
            logos[]{
              "imageUrl": asset->url
            }
          }
        }[0].partnersClientsSection.logos `
    )
      .then((data) => {
        setClientLogos(data)
      })
      .catch(console.error);
    receiveDataFromChild()
  }, [])


  useEffect(() => {
    const currentLanguage = getLangue();
    setLanguage(currentLanguage);
  }, []);

  const changeLangue = (newLang) => {
    setLangue(newLang);
    setLanguage(newLang);
  };

  const resetLangue = () => {
    removeLangue();
    setLanguage(getLangue());
  };

  const receiveDataFromChild = (data) => {
    console.log("data", data);
    setData(data)
  };

  const getLanguage = (data) => {
    console.log("laguage ici", data);
    setLanguage(data);
  };

  console.log(item)

  const replaceUndefined = (imageUrl) => {
    if (!imageUrl) {
      console.error('Invalid image URL:', imageUrl);
      return null; // Retourne null si l'URL de l'image est invalide
    }

    // Remplacez "undefined" par "5pzop2m2" dans l'URL de l'image
    const newImageUrl = imageUrl.replace('undefined', '5pzop2m2');
    const newImageUrlF = newImageUrl.replace('undefined', 'production');
    return newImageUrlF; // Retourne la nouvelle URL modifiée
  }

  useEffect(() => {

    sanityClient.fetch(
      `*[_type == "otherPages" ][]`
    )
      .then((data) => {
        console.log(data);

        setData(data);
      })
      .catch(console.error);





  }, []);
  return (
    <>
      <Header2 sendDataToParent={receiveDataFromChild} sendLanguage={getLanguage} />
      <Sidebar />
      <Banner title={language === 'fr' ? item?.titlePost?.fr : item?.titlePost?.en} backgroundImageUrl={replaceUndefined(urlFor(item?.imageBloc).url())} />

      <div class="sidebar-page-container bg-white">
        <div class="auto-container">
          <div class="row clearfix">
            <div class="content-side col-lg-8 col-md-12 col-sm-12">
              <div class="blog-detail">
                <div class="inner-box">
                  <div class="image">
                    <img src={replaceUndefined(urlFor(item?.imageBloc).url())} alt="" />
                  </div>

                  <div class="lower-content">
                    <ul class="post-meta">
                      {/* <li>
                        <span class="icon flaticon-chat-bubble"></span>Comments
                      </li> */}

                      <li>
                        <span class="icon flaticon-user-2"></span>Admin
                      </li>

                      {/*  <li>
                        <span class="icon flaticon-timetable"></span>12 Dec 2023
                      </li> */}
                    </ul>

                    <h4>{language === 'fr' ? item?.titlePost?.fr : item?.titlePost?.en}</h4>

                    {language === 'fr' ? item?.description?.fr && item?.description?.fr.map((block, idx) => (
                      <p key={idx}>{block?.children[0].text}</p>
                    )) : item?.description?.en && item?.description?.en.map((block, idx) => (
                      <p key={idx}>{block?.children[0].text}</p>
                    ))}





                  </div>
                </div>

                {/* <div class="social-sharing">
                  <div class="share-inner clearfix">
                    <div class="pull-left">
                      <ul class="social-box">
                        <li class="facebook">
                          <a href="#">
                            <span class="icon fa fa-facebook"></span> facebook{" "}
                            <span class="number">0</span>
                          </a>
                        </li>

                        <li class="tweet">
                          <a href="#">
                            <span class="icon fa fa-twitter"></span> Tweet{" "}
                            <span class="number">0</span>
                          </a>
                        </li>

                        <li class="pinterest">
                          <a href="#">
                            <span class="icon fa fa-pinterest"></span> Pinterest{" "}
                            <span class="number">0</span>
                          </a>
                        </li>

                        <li class="dribbble">
                          <a href="#">
                            <span class="icon fa fa-dribbble"></span> Dribbble{" "}
                            <span class="number">0</span>
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div class="pull-right">
                      <div class="share">
                        <span class="icon fa fa-share"></span>
                        0 <br /> Shares
                      </div>
                    </div>
                  </div>
                </div>
 */}





              </div>
            </div>

            <div class="sidebar-side col-lg-4 col-md-12 col-sm-12">
              <aside class="sidebar sticky-top">

                <RelatedBlog2 language={language} />
                <CategoryBlock language={language} />






              </aside>
            </div>
          </div>
        </div>
      </div>

      <section
        className="clients-section-three style-two"
        style={{ backgroundImage: 'url(assets/images/background/pattern-21.png)' }}
      >
        <div className="auto-container">
          <div className="row clearfix">


            <div className="clients-column col-lg-7 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="row clearfix">
                  {clientLogos && clientLogos?.map((logo, index) => (
                    <div className="client-box col-lg-4 col-md-4 col-sm-12" key={index}>
                      <div className="image">
                        <a href="#">
                          <img src={logo?.imageUrl} alt={logo?.name} style={{
                            height: "150px",
                            width: "150px"
                          }} />
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer language={language} />
    </>
  );
}

export default ActualiteDetails;
