import React from "react";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import Banner from "../../../components/Banner";
import Footer from "../../../components/Footer";

function About() {
  return (
    <>
      <Header />
      <Sidebar />
      <Banner />
      <section class="about-section style-two">
        <div
          class="pattern-layer"
          style={{
            backgroundImage: "url(assets/images/background/pattern-16.png)",
          }}
        ></div>

        <div class="auto-container">
          <div class="row clearfix">
            <div class="image-column col-lg-6 col-md-12 col-sm-12">
              <div
                class="inner-column wow fadeInLeft"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div class="color-layer"></div>

                <div class="title">ABOUT US</div>

                <div class="image">
                  <div class="color-layer-two"></div>

                  <div class="color-layer-three"></div>

                  <img src="assets/images/resource/about.jpg" alt="" />
                </div>

                <div class="experiance">
                  <div class="inner">
                    <span class="number">34</span>
                    YEARS OF <br /> EXPERIENCE
                  </div>
                </div>
              </div>
            </div>

            <div class="content-column col-lg-6 col-md-12 col-sm-12">
              <div class="inner-column">
                <div class="sec-title style-two">
                  <div class="separater"></div>

                  <div class="title">About Us</div>

                  <h2>
                    We Will Satisfy You By Our <br /> Work ideas
                  </h2>
                </div>

                <div class="text">
                  <p>
                    At vero eos et accusamus et iusto odio digni goikussimos
                    ducimus qui to bonfoe blanditiis praese. Ntium voluum
                    deleniti atque corrupti quos. of a page a reload when
                    looking at its layout.{" "}
                  </p>

                  <p>
                    The point of using Lorem Ipsum is that it has pi motive
                    re-or-less normal distribution of letters, as opposed.
                  </p>
                </div>

                <div class="clearfix">
                  <div class="pull-left">
                    <a href="#" class="read-more">
                      Read More <span class="plus flaticon-plus-symbol"></span>
                    </a>
                  </div>

                  <div class="pull-left">
                    <div class="phone">
                      Call To Ask Any Question <strong>666 888 0000</strong>
                    </div>
                  </div>
                </div>

                <div class="signature">
                  <img src="assets/images/icons/signature-1.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="counter-section">
        <div class="auto-container">
          <div class="fact-counter-two">
            <div class="clearfix">
              <div class="column counter-column col-lg-3 col-md-6 col-sm-12">
                <div
                  class="inner wow fadeInLeft"
                  data-wow-delay="0ms"
                  data-wow-duration="1500ms"
                >
                  <div class="content">
                    <div class="lines"></div>

                    <div class="icon flaticon-engineer"></div>

                    <div class="count-outer count-box">
                      <span class="count-text" data-speed="3000" data-stop="84">
                        0
                      </span>
                    </div>

                    <h4 class="counter-title">
                      Professional Workers in Company
                    </h4>
                  </div>
                </div>
              </div>

              <div class="column counter-column col-lg-3 col-md-6 col-sm-12">
                <div
                  class="inner wow fadeInLeft"
                  data-wow-delay="300ms"
                  data-wow-duration="1500ms"
                >
                  <div class="content">
                    <div class="lines"></div>

                    <div class="icon flaticon-buildings"></div>

                    <div class="count-outer count-box alternate">
                      <span
                        class="count-text"
                        data-speed="5000"
                        data-stop="2.5"
                      >
                        0
                      </span>
                      K
                    </div>

                    <h4 class="counter-title">
                      Projects Competed Successfully
                    </h4>
                  </div>
                </div>
              </div>

              <div class="column counter-column col-lg-3 col-md-6 col-sm-12">
                <div
                  class="inner wow fadeInLeft"
                  data-wow-delay="600ms"
                  data-wow-duration="1500ms"
                >
                  <div class="content">
                    <div class="lines"></div>

                    <div class="icon flaticon-man"></div>

                    <div class="count-outer count-box">
                      <span class="count-text" data-speed="2000" data-stop="12">
                        0
                      </span>
                      +
                    </div>

                    <h4 class="counter-title">Customers Benefit Every Day</h4>
                  </div>
                </div>
              </div>

              <div class="column counter-column col-lg-3 col-md-6 col-sm-12">
                <div
                  class="inner wow fadeInLeft"
                  data-wow-delay="900ms"
                  data-wow-duration="1500ms"
                >
                  <div class="content">
                    <div class="lines"></div>

                    <div class="icon flaticon-trophy-1"></div>

                    <div class="count-outer count-box">
                      <span class="count-text" data-speed="3500" data-stop="05">
                        0
                      </span>
                    </div>

                    <h4 class="counter-title">
                      Received Awards & Certifications
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="video-project-section style-two">
        <div
          class="image-layer"
          style={{ backgroundImage: "url(assets/images/background/7.jpg)" }}
        ></div>

        <div
          class="pattern-layer"
          style={{
            backgroundImage: "url(assets/images/background/pattern-14.png)",
          }}
        ></div>

        <div class="auto-container">
          <div class="row clearfix">
            <div class="content-column col-lg-7 col-md-12 col-sm-12">
              <div class="inner-column">
                <div class="sec-title style-two light">
                  <div class="separater"></div>

                  <div class="title">Working With Us</div>

                  <h2>
                    Your New Project <br /> Watch Our Video Now
                  </h2>
                </div>

                <div class="text">
                  Hopefully i can inspire and encourage you to try out new{" "}
                  <br /> way of building this video is a little more technical.
                </div>
              </div>
            </div>

            <div class="video-column col-lg-5 col-md-12 col-sm-12">
              <div class="inner-column">
                <div class="video-box">
                  <figure class="video-image">
                    <img src="assets/images/resource/video-img.jpg" alt="" />
                  </figure>

                  <a
                    href="https://www.youtube.com/watch?v=kxPCFljwJws"
                    class="lightbox-image overlay-box"
                  >
                    <span class="flaticon-play-arrow">
                      <i class="ripple"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="skills-section style-two">
        <div class="auto-container">
          <div class="row clearfix">
            <div class="skill-column col-lg-6 col-md-12 col-sm-12">
              <div class="inner-column">
                <div class="sec-title style-two">
                  <div class="separater"></div>

                  <div class="title">Our Special Skills</div>

                  <h2>
                    Easy Way To Collaborate <br /> Our Company
                  </h2>
                </div>

                <div class="text">
                  It is a long established fact that a reader will be distracted
                  by the <br /> readable content of a page when looking at its
                  layout.
                </div>

                <div class="skills">
                  <div class="skill-item">
                    <div class="skill-header clearfix">
                      <div class="skill-title">Industrial</div>

                      <div class="skill-percentage">
                        <div class="count-box">
                          <span
                            class="count-text"
                            data-speed="2000"
                            data-stop="65"
                          >
                            0
                          </span>
                          %
                        </div>
                      </div>
                    </div>

                    <div class="skill-bar">
                      <div class="bar-inner">
                        <div class="bar progress-line" data-width="65"></div>
                      </div>
                    </div>
                  </div>

                  <div class="skill-item">
                    <div class="skill-header clearfix">
                      <div class="skill-title">Construction</div>

                      <div class="skill-percentage">
                        <div class="count-box">
                          <span
                            class="count-text"
                            data-speed="2000"
                            data-stop="85"
                          >
                            0
                          </span>
                          %
                        </div>
                      </div>
                    </div>

                    <div class="skill-bar">
                      <div class="bar-inner">
                        <div class="bar progress-line" data-width="85"></div>
                      </div>
                    </div>
                  </div>

                  <div class="skill-item">
                    <div class="skill-header clearfix">
                      <div class="skill-title">Remodeling</div>

                      <div class="skill-percentage">
                        <div class="count-box">
                          <span
                            class="count-text"
                            data-speed="2000"
                            data-stop="75"
                          >
                            0
                          </span>
                          %
                        </div>
                      </div>
                    </div>

                    <div class="skill-bar">
                      <div class="bar-inner">
                        <div class="bar progress-line" data-width="75"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <a href="about.html" class="read-more">
                  Contact Now{" "}
                  <span class="plus-box flaticon-plus-symbol"></span>
                </a>
              </div>
            </div>

            <div class="team-column col-lg-6 col-md-12 col-sm-12">
              <div class="inner-column">
                <div class="row clearfix">
                  <div class="team-block-two col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-box">
                      <figure class="image-box">
                        <img src="assets/images/resource/team-5.jpg" alt="" />

                        <div class="overlay-box">
                          <div class="border-one"></div>

                          <div class="border-two"></div>

                          <div class="overlay-inner">
                            <div class="content">
                              <h4>
                                <a href="team.html">Willie Rocke</a>
                              </h4>

                              <div class="category">Map Designer</div>

                              <ul class="social-box">
                                <li>
                                  <a href="#" class="fa fa-facebook-f"></a>
                                  <i>facebook</i>
                                </li>

                                <li>
                                  <a href="#" class="fa fa-google"></a>
                                  <i>Google +</i>
                                </li>

                                <li>
                                  <a href="#" class="fa fa-twitter"></a>
                                  <i>Twitter</i>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </figure>
                    </div>
                  </div>

                  <div class="team-block-two col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-box">
                      <figure class="image-box">
                        <img src="assets/images/resource/team-6.jpg" alt="" />

                        <div class="overlay-box">
                          <div class="border-one"></div>

                          <div class="border-two"></div>

                          <div class="overlay-inner">
                            <div class="content">
                              <h4>
                                <a href="team.html">Willie Rocke</a>
                              </h4>

                              <div class="category">Map Designer</div>

                              <ul class="social-box">
                                <li>
                                  <a href="#" class="fa fa-facebook-f"></a>
                                  <i>facebook</i>
                                </li>

                                <li>
                                  <a href="#" class="fa fa-google"></a>
                                  <i>Google +</i>
                                </li>

                                <li>
                                  <a href="#" class="fa fa-twitter"></a>
                                  <i>Twitter</i>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </figure>
                    </div>
                  </div>

                  <div class="team-block-two col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-box">
                      <figure class="image-box">
                        <img src="assets/images/resource/team-7.jpg" alt="" />

                        <div class="overlay-box">
                          <div class="border-one"></div>

                          <div class="border-two"></div>

                          <div class="overlay-inner">
                            <div class="content">
                              <h4>
                                <a href="team.html">Willie Rocke</a>
                              </h4>

                              <div class="category">Map Designer</div>

                              <ul class="social-box">
                                <li>
                                  <a href="#" class="fa fa-facebook-f"></a>
                                  <i>facebook</i>
                                </li>

                                <li>
                                  <a href="#" class="fa fa-google"></a>
                                  <i>Google +</i>
                                </li>

                                <li>
                                  <a href="#" class="fa fa-twitter"></a>
                                  <i>Twitter</i>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </figure>
                    </div>
                  </div>

                  <div class="team-block-two col-lg-6 col-md-6 col-sm-12">
                    <div class="inner-box">
                      <figure class="image-box">
                        <img src="assets/images/resource/team-8.jpg" alt="" />

                        <div class="overlay-box">
                          <div class="border-one"></div>

                          <div class="border-two"></div>

                          <div class="overlay-inner">
                            <div class="content">
                              <h4>
                                <a href="team.html">Willie Rocke</a>
                              </h4>

                              <div class="category">Map Designer</div>

                              <ul class="social-box">
                                <li>
                                  <a href="#" class="fa fa-facebook-f"></a>
                                  <i>facebook</i>
                                </li>

                                <li>
                                  <a href="#" class="fa fa-google"></a>
                                  <i>Google +</i>
                                </li>

                                <li>
                                  <a href="#" class="fa fa-twitter"></a>
                                  <i>Twitter</i>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="choice-section style-two">
        <div class="auto-container">
          <div class="row clearfix">
            <div class="image-column col-lg-6 col-md-12 col-sm-12">
              <div class="inner-column">
                <div
                  class="pattern-layer"
                  style={{
                    backgroundImage: "url(assets/images/icons/pattern-4.png)",
                  }}
                ></div>

                <div class="image">
                  <img src="assets/images/resource/choice.jpg" alt="" />
                </div>

                <div class="image-two">
                  <img src="assets/images/resource/choice-1.jpg" alt="" />
                </div>

                <div class="stamp-icon">
                  <img src="assets/images/icons/stamp.png" alt="" />
                </div>
              </div>
            </div>

            <div class="content-column col-lg-6 col-md-12 col-sm-12">
              <div class="inner-column">
                <div class="sec-title style-two">
                  <div class="separater"></div>

                  <div class="title">Why Our Choice</div>

                  <h2>
                    Why We Are Best Construction <br /> Company in The World
                  </h2>
                </div>

                <div class="text">
                  <p>
                    Building Expert explains how you can enjoy high end flooring
                    trends like textured wood and with new laminate flooring. As
                    the general contractor, we first create the highe rust
                    integrity with our clients.
                  </p>

                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p>
                </div>

                <div class="row clearfix">
                  <div class="column col-lg-6 col-md-6 col-sm-12">
                    <ul class="list-style-two">
                      <li>Masonry Works And Plaster</li>

                      <li>Pre Engineered Structures</li>
                    </ul>
                  </div>

                  <div class="column col-lg-6 col-md-6 col-sm-12">
                    <ul class="list-style-two">
                      <li>Per Cast Work</li>

                      <li>Engineered Structures</li>
                    </ul>
                  </div>
                </div>

                <a href="#" class="read-more">
                  Read More <span class="plus flaticon-plus-symbol"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        class="clients-section-three style-two"
        style={{
          backgroundImage: "url(assets/images/background/pattern-21.png)",
        }}
      >
        <div class="auto-container">
          <div class="row clearfix">
            <div class="title-column col-lg-5 col-md-12 col-sm-12">
              <div class="inner-column">
                <div class="title-box">
                  <div class="title">
                    <span class="separater"></span>
                    Get Quote
                  </div>

                  <h3>Request A Quote</h3>

                  <div class="text">
                    At vero eos et accusamus et iusto odio digni goiku ssimos{" "}
                    <br /> ducimus qui blanditiis praese.
                  </div>
                </div>

                <a href="contact.html" class="read-more">
                  Contact Now{" "}
                  <span class="plus-box flaticon-plus-symbol"></span>
                </a>
              </div>
            </div>

            <div class="clients-column col-lg-7 col-md-12 col-sm-12">
              <div class="inner-column">
                <div class="row clearfix">
                  <div class="client-box col-lg-4 col-md-4 col-sm-12">
                    <div class="image">
                      <a href="#">
                        <img src="assets/images/clients/9.png" alt="" />
                      </a>
                    </div>
                  </div>

                  <div class="client-box col-lg-4 col-md-4 col-sm-12">
                    <div class="image">
                      <a href="#">
                        <img src="assets/images/clients/10.png" alt="" />
                      </a>
                    </div>
                  </div>

                  <div class="client-box col-lg-4 col-md-4 col-sm-12">
                    <div class="image">
                      <a href="#">
                        <img src="assets/images/clients/11.png" alt="" />
                      </a>
                    </div>
                  </div>

                  <div class="client-box col-lg-4 col-md-4 col-sm-12">
                    <div class="image">
                      <a href="#">
                        <img src="assets/images/clients/12.png" alt="" />
                      </a>
                    </div>
                  </div>

                  <div class="client-box col-lg-4 col-md-4 col-sm-12">
                    <div class="image">
                      <a href="#">
                        <img src="assets/images/clients/13.png" alt="" />
                      </a>
                    </div>
                  </div>

                  <div class="client-box col-lg-4 col-md-4 col-sm-12">
                    <div class="image">
                      <a href="#">
                        <img src="assets/images/clients/14.png" alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default About;
