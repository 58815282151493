import { getImageDimensions } from "@sanity/asset-utils";
import React from "react";
import { urlFor } from "./sanityClient";

// Barebones lazy-loaded image component
const SampleImageComponent = ({ value, isInline }) => {
  const { width, height } = getImageDimensions(value);
  return (
    <div className={`py-5`}>
      <div className={`d-flex justify-content-center`}>
        <img
          src={urlFor(value).fit("max").url()}
          loading="lazy"
          alt=""
          style={{
            // Display alongside text if image appears inside a block text span
            display: isInline ? "inline-block" : "block",
            resize: "both",

            // Avoid jumping around with aspect-ratio CSS property
            aspectRatio: width / height,
          }}
        />
      </div>
    </div>
  );
};

// Barebones lazy-loaded image component
const SampleContactComponent = ({ value }) => {
  return (
    <div className={`inline-flex items-center`}>
      <img
        src={urlFor(value.image).fit("max").url()}
        loading="lazy"
        height={25}
        width={25}
        alt=""
      />
      <span className="text-base">{value.value}</span>
    </div>
  );
};



export const components = (isMobile) => {
  return {
    types: {
      image: SampleImageComponent,
      contact: SampleContactComponent,
      // Any other custom types you have in your content
      // Examples: mapLocation, contactForm, code, featuredProjects, latestNews, etc.
    },
    marks: {
      // Ex. 1: custom renderer for the em / italics decorator
      em: ({ children }) => (
        <em className="text-gray-600 font-semibold">{children}</em>
      ),
      strong: ({ children }) => (
        <span className="text-base font-bold">{children}</span>
      ),
      underline: ({ children }) => (
        <span className="underline underline-offset-2">{children}</span>
      ),

      // Ex. 2: rendering a custom `link` annotation
      link: ({ value, children }) => {
        const target = (value?.href || "").startsWith("http")
          ? "_blank"
          : undefined;
        return (
          <a
            href={value?.href}
            className="text-blue-700 font-semibold"
            target={target}
          >
            {children}
          </a>
        );
      },
    },
    block: {
      // Ex. 1: customizing common block types
      h1: ({ children }) => (
        <h1 className="text-3xl font-semibold my-2">
          {children}
          <br />
        </h1>
      ),

      h2: ({ children }) => (
        <h2 className="text-2xl font-semibold my-2">{children}</h2>
      ),
      h3: ({ children }) => (
        <h3 className="text-xl font-semibold my-2">
          {children}
          <br />
        </h3>
      ),
      h4: ({ children }) => (
        <h4 className="text-lg font-semibold my-2">
          {children}
          <br />
        </h4>
      ),
      blockquote: ({ children }) => (
        <blockquote className="border-l-purple-500">{children}</blockquote>
      ),

      // Ex. 2: rendering custom styles
      customHeading: ({ children }) => (
        <h2 className="text-lg text-primary">{children}</h2>
      ),
      p: ({ children }) => (
        <p className="text-lg text-primary  py-2">{children}</p>
      ),
      normal: ({ children }) => (
        <p className="py-2">{children}</p>
      ),
    },

    list: {
      // Ex. 1: customizing common list types
      bullet: ({ children }) => (
        <ul className="mt-xl my-list-style">{children}</ul>
      ),
      number: ({ children }) => <ol className="mt-lg">{children}</ol>,

      // Ex. 2: rendering custom lists
      checkmarks: ({ children }) => (
        <ol className="m-auto text-lg">{children}</ol>
      ),
    },

    listItem: {
      // Ex. 1: customizing common list types
      bullet: ({ children }) => (
        <li
          className={`${isMobile ? "ml-4" : "ml-12"} my-list-style`}
        // style={{ listStyle: two-apples}} // circlegeorgian  disclosure-open disc disclosure-closed \1F44D \1FAF1 kannada custom-counter-style
        >
          {children}
        </li>
      ),

      // Ex. 2: rendering custom list items
      checkmarks: ({ children }) => <li>✅ {children}</li>,
    },
    hardBreak: false,
  };
};
