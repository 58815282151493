import client from './../sanity/sanityClient';

const lang = sessionStorage.getItem('langue') || 'fr';

export const fetchTraiteConventionsData = async () => {
  const query = `
    *[_type == "traiteConventions"] {
      bannerSection[]{
        "textePrincipal": textePrincipal.${lang},
        "texteSecondaire": texteSecondaire.${lang},
        "description": description.${lang},
        imageFond{
          asset->{
            _id,
            url
          }
        }
      },
      blogPosts[]{
        title->{${lang}},
        link,
        date
      },
      sidebarLinks[]{
        title->{${lang}},
        link,
        date
      },
      clientLogos[]{
        asset->{
          _id,
          url
        }
      },
      quote{
        title->{${lang}},
        text->{${lang}}
      }
    }
  `;
  return await client.fetch(query);
};
