import React, { useEffect, useState } from "react";
import Banner from "../../../components/Banner";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import { fetchPartnersClientsSectionData } from "../../../network/services/fetchHomeData";
import { fetchHeroSectionData, fetchPresentationRequestQuoteSectionData, getProjectSingleSection } from "../../../network/services/fetchPresentation";
import ClientSection from "../../../components/ClientSection";
import Loading from "../../../components/Loading";

function Presentation() {
  const [heroSection, setHeroSection] = useState();
  const [projectData, setProjectData] = useState();
  const [clientLogos, setClientLogos] = useState();
  const [requestQuoteSection, setRequestQuoteSection] = useState();
  const lang = sessionStorage.getItem('langue'); // Récupérer la langue stockée ou utiliser le français par défaut

  useEffect(() => {
    const getData = async () => {
      const heroSectionData = await fetchHeroSectionData();
      const projectSectionData = await getProjectSingleSection();
      const requestQuoteSectionData = await fetchPresentationRequestQuoteSectionData();

      setHeroSection(heroSectionData);
      setProjectData(projectSectionData)
      setRequestQuoteSection(requestQuoteSectionData);
      const clientLogosData = await fetchPartnersClientsSectionData();
      const limitedClientLogosData = clientLogosData.slice(0, 6); // Limite à 6 éléments
      setClientLogos(limitedClientLogosData);
    };
    getData();
  }, []);
  return (
    <>
      {heroSection ? (<>
        <Header />
        <Sidebar />
        {heroSection ? (
          <Banner title={heroSection[0]?.textePrincipal} backgroundImageUrl={heroSection[0]?.imageFond?.url} />
        ) : ""}

        <section className="project-single-section">
          <div className="auto-container">
            <div className="upper-section">
              <div className="row clearfix">
                <div className="image-column col-lg-8 col-md-8 col-sm-12">
                  <div className="image">
                    <img src={projectData?.imageLeft?.url} alt="" />
                  </div>
                </div>
                <div className="image-column col-lg-4 col-md-4 col-sm-12">
                  <div className="inner-column">
                    {projectData?.upperSection?.map((image, idx) => (
                      <div className="image" key={idx}>
                        <img src={image.asset?.url} alt={`Upper section image ${idx}`} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="lower-section">
              <div className="row clearfix">
                <div className="content-column col-lg-8 col-md-12 col-sm-12">
                  {/* <h3>{projectData?.lowerSection[0]?.children[0]?.text}</h3> */}
                  {projectData?.lowerSection && projectData?.lowerSection?.slice(1).map((block, idx) => (
                    <p key={idx}>{block.children[0]?.text}</p>
                  ))}
                </div>

                <div className="content-column col-lg-4 col-md-12 col-sm-12">
                  <h3>{projectData?.titre}</h3>
                  {projectData?.projectInfo?.content && projectData?.projectInfo?.content?.map((block, idx) => (
                    <p key={idx}>{block.children[0]?.text}</p>
                  ))}
                  <ul className="info-list">
                    {projectData.projectInfo?.infoList && projectData.projectInfo?.infoList?.map((info, idx) => (
                      <li key={idx}>
                        <span>{info.titre}:</span> {info.value}
                      </li>
                    ))}
                  </ul>

                  <div className="solution-box">
                    <div
                      className="inner-box"
                      style={{ backgroundImage: `url(${projectData?.solutionBox?.backgroundImage})` }}
                    >
                      <div className="title">{projectData?.solutionBox?.title}</div>
                      <h2>{projectData?.solutionBox?.heading}</h2>
                      <div className="text">{projectData?.solutionBox?.text}</div>
                      <a href={projectData?.solutionBox?.buttonLink} className="theme-btn btn-style-seven">
                        {projectData?.solutionBox?.buttonText}
                        <span className="plus-box flaticon-plus-symbol"></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>

        <ClientSection />
        <Footer />
      </>) : <Loading />}
    </>
  );
}

export default Presentation;
