import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import routes from "../routes/routes";
import "./breadcrumb.css";
import { getLangue } from "../network/auth/Auth";

const Breadcrumb = ({ menuName, language }) => {
  const location = useLocation();

  const pathParts = location.pathname.split("/").filter(Boolean);

  const findRouteName = (path) => {
    for (const routeGroup of routes) {
      const matchingRoute = routeGroup.routes.find(
        (route) => route.path === path
      );
      if (matchingRoute) return matchingRoute.name;
    }
    return null;
  };

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb bread-design">
          <li className="breadcrumb-item">
            <Link to="/"> {language === "fr" ? "Accueil" : "Home"}</Link>
          </li>
          {pathParts.map((part, index) => {
            const path = `/${pathParts.slice(0, index + 1).join("/")}`;
            const isLast = index === pathParts.length - 1;
            const routeName = findRouteName(path) || decodeURIComponent(part);

            return isLast ? (
              <li
                key={index}
                className="breadcrumb-item active"
                aria-current="page"
              >
                {routeName === "Contact"
                  ? language === "fr"
                    ? "Contact"
                    : "Contact"
                  : routeName === "contact"
                  ? language === "fr"
                    ? "Actualités"
                    : "News"
                  : routeName}
              </li>
            ) : (
              <li key={index} className="breadcrumb-item">
                {language === "fr"
                  ? menuName?.fr || decodeURIComponent(part.replace(/-/g, " "))
                  : menuName?.en || decodeURIComponent(part.replace(/-/g, " "))}
              </li>
            );
          })}
        </ol>
      </nav>
    </div>
  );
};

export default Breadcrumb;
